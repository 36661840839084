import { ref, computed, watch } from 'vue'
import { useStore } from 'vuex'
import { isMfaEnabled } from '@/utils/auth'
import { StateManager } from '../utils'
import useAnalytics from './useAnalytics'

export default () => {
  const { getters } = useStore()
  const { trackEvent, Label } = useAnalytics()

  const stateManager = new StateManager()
  const isBannerVisible = ref(stateManager.shouldShowBanner())
  const mfaEnrollments = computed(() => getters['auth/mfa/mfaEnrollments'])
  const shouldShowBanner = computed(
    () =>
      isMfaEnabled() &&
      isBannerVisible.value &&
      mfaEnrollments.value?.length === 0
  )

  watch(
    mfaEnrollments,
    newMfaEnrollments => {
      if (newMfaEnrollments.length > 0) {
        stateManager.remove()
        isBannerVisible.value = false
      }
    },
    { immediate: true }
  )

  const handleBannerClose = () => {
    stateManager.update()
    isBannerVisible.value = false
    trackEvent(Label.CLOSE_BANNER)
  }

  return {
    shouldShowBanner,
    handleBannerClose,
  }
}
