<template>
  <p class="description">{{ t('description') }}</p>
  <h3 class="subtitle">{{ t('subtitle') }}</h3>
  <ol class="steps">
    <!-- eslint-disable vue/no-v-html -->
    <li
      v-for="(step, index) in steps"
      :key="index"
      class="step"
      v-html="step"
    />
    <!-- eslint-enable vue/no-v-html -->
  </ol>
  <Actions :class="['actions', { 'flex-start': isRegistration }]">
    <DSButton
      variant="secondary"
      data-testid="cancel"
      :disabled="isLoading"
      @click="!isRegistration ? $emit('close') : $emit('skip')"
    >
      {{ !isRegistration ? t('buttons.cancel') : t('buttons.skip') }}
    </DSButton>
    <DSButton variant="primary" :loading="isLoading" @click="handlePanelEnable">
      {{ t('buttons.enable') }}
    </DSButton>
  </Actions>
</template>

<script setup lang="ts">
import { DSButton } from '@prolific-oss/design-system'
import { useI18n } from 'vue-i18n'
import Actions from '@/components/form/Actions.vue'
import { usePanel } from './composables'

const { t } = useI18n()
const { isLoading, handlePanelEnable, steps } = usePanel()

defineProps({
  isRegistration: Boolean,
})

defineEmits<{
  (e: 'close'): void
  (e: 'skip'): void
}>()
</script>

<style lang="scss" scoped>
.description {
  margin-bottom: var(--space-4);
}

.subtitle {
  font-size: var(--space-4);
  font-weight: var(--font-weight-600);
  margin-bottom: var(--space-2);
}

.steps {
  padding-left: var(--space-8);
  margin-bottom: var(--space-0);
}

.step {
  margin-bottom: var(--space-1);
}

.actions {
  display: flex;
  justify-content: flex-end;
  margin-top: var(--space-4);
  border-bottom-left-radius: var(--space-2);
  border-bottom-right-radius: var(--space-2);
  flex-direction: column-reverse;

  @media screen and (width >= $sm) {
    flex-direction: row;
  }

  :deep(.button) {
    width: 100%;
    margin-bottom: $space-3;

    @media screen and (width >= $sm) {
      width: unset;
      margin-bottom: unset;
    }
  }

  &.flex-start {
    justify-content: flex-start;
  }
}
</style>

<i18n lang="yaml">
en:
  description: 'Strengthen your account security by enabling multifactor authentication (MFA). This extra layer helps prevent unauthorized access, safeguarding your personal data and research activity.'
  subtitle: 'How to set it up:'
  steps:
    - 'Download a trusted authenticator, such as a smartphone app (e.g., Google Authenticator, Duo Mobile), a password manager (e.g., 1Password, LastPass), or a browser extension.'
    - 'Click the "Enable MFA" button below.'
    - 'Scan the QR code, then enter the code generated by your authenticator to complete the setup.'
  buttons:
    cancel: 'Cancel'
    enable: 'Enable MFA'
    continue: 'Continue to MFA set up'
    skip: 'Skip'
</i18n>
