/** For all routes specific to participant
 *  Access to these routes is restricted to a user role of participant
 */
import { trackAccountEvent } from '@/analytics/common'
import { DATA_COLLECTION_TASK_BUILDER } from '@/constants'
import rollbar from '@/integrations/rollbar'
import store from '@/store'
import { getErrorMessage } from '@/utils'
import notifier from '@/utils/notifier'

export default [
  {
    path: '',
    name: 'home',
    redirect: { name: 'studies' },
  },
  {
    path: 'studies',
    name: 'studies',
    component: () => import('@/views/participant/Studies.vue'),
    beforeEnter: async (to, from, next) => {
      // Fetch questions once if not already fetched. This is used for the Mug Card w/ questions in the Study page
      const promises = []

      if (!store.getters['participant/eligibility/categories'].length) {
        promises.push(store.dispatch('participant/eligibility/fetchList'))
      }

      // Fetch studies
      promises.push(store.dispatch('participant/studies/fetchList'))

      await Promise.all(promises)

      // Extract onboarding questions
      store.dispatch('participant/eligibility/extractOnboardQuestions')

      next()
    },
    props: () => ({
      studies: store.getters['participant/studies/displayStudiesList'],
    }),
  },
  {
    path: 'studies/:id',
    name: 'study',
    beforeEnter: (to, from, next) => {
      // TODO: remove this once cause of issue ES-318 has been resolved
      if (!to.params.id) {
        // the study id is undefined for some reason
        rollbar.error('ES-318: studies/:id `to.params.id` undefined')
        return next({ name: 'error.404' })
      }

      store
        .dispatch('participant/studies/fetchSingle', to.params.id)
        .then(() => next())
    },
    component: () => import('@/views/participant/Study.vue'),
    props: () => ({
      study: store.getters['participant/studies/denormalized'],
      survey: store.getters['participant/surveys/survey'],
    }),
  },
  {
    path: '/submissions/complete',
    name: 'submissions.complete',
    component: () => import('@/views/participant/Complete.vue'),
    meta: {
      hideNav: true,
    },
  },
  {
    path: 'submissions',
    name: 'submissions',
    component: () => import('@/views/participant/ParticipantSubmissions.vue'),
  },
  {
    // This route is used by the BE when a user gets a message to return a submission
    path: 'submissions/:id',
    name: 'submission',
    component: () => import('@/views/participant/ParticipantSubmissions.vue'),
  },
  {
    path: 'about-you',
    name: 'eligibility',
    component: () => import('@/views/participant/eligibility/Categories.vue'),
    beforeEnter: (to, from, next) =>
      store.dispatch('participant/eligibility/fetchList').then(() => next()),
    props: () => ({
      categories: store.getters['participant/eligibility/categories'],
    }),
  },
  {
    path: 'about-you/:id',
    name: 'eligibility.category',
    component: () => import('@/views/participant/eligibility/Category.vue'),
    beforeEnter: async (to, from, next) => {
      const promises = []
      // no categories in local state, re-fetch
      if (store.getters['participant/eligibility/categories'].length === 0) {
        promises.push(store.dispatch('participant/eligibility/fetchList'))
      }

      await Promise.all(promises)

      // if category not found, redirect to 404
      if (!store.state.participant.eligibility.categories[to.params.id]) {
        return next({ name: 'error.404' })
      }

      next()
    },
    props: route => ({
      category: store.getters['participant/eligibility/category'](
        route.params.id
      ),
    }),
  },
  {
    path: 'account',
    name: 'account',
    redirect: { name: 'account.general' },
    component: () => import('@/views/account/common/AccountLayout.vue'),
    children: [
      {
        path: 'link-paypal',
        name: 'account.linkPaypal',
        beforeEnter: async (to, from, next) => {
          store.dispatch('global/setLoading', true)
          const paypalConnectBroadcast = new BroadcastChannel(
            'prolific-paypal-connect'
          )
          const { code, scope, state: nonce } = to.query

          try {
            await store.dispatch('auth/linkPaypal', { code, scope, nonce })
            paypalConnectBroadcast.postMessage({
              message: 'paypal-connect-success',
            })
          } catch (error) {
            const errorMessage =
              getErrorMessage(error) || 'Error linking PayPal'
            trackAccountEvent(
              'paypal_email_changed',
              errorMessage,
              'notification'
            )
            paypalConnectBroadcast.postMessage({
              message: 'paypal-connect-fail',
              error: error.detail,
            })
          } finally {
            window.close()
            store.dispatch('global/setLoading', false)
            next({ name: 'account.general' })
          }
        },
      },
      {
        path: 'general',
        name: 'account.general',
        beforeEnter: (to, from, next) => {
          store.dispatch('auth/fetchUser').then(
            () => {
              next()
            },
            () => {
              // continue to load this route, even on user refresh failure
              next()
            }
          )
        },
        component: () => import('@/views/participant/account/General.vue'),
      },
      {
        path: 'preferences',
        name: 'account.preferences',
        component: () => import('@/views/account/common/Preferences.vue'),
      },
    ],
  },
  {
    path: '/referrals',
    name: 'referrals',
    component: () => import('@/views/participant/referrals/ReferralsPage.vue'),
    beforeEnter: async (to, from, next) => {
      await store.dispatch('participant/referralCampaigns/getReferralCampaigns')
      const referralCampaigns =
        store.state.participant.referralCampaigns.campaigns
      if (referralCampaigns.length === 0) {
        next({ name: 'error.404' })
      } else {
        next()
      }
    },
  },
  {
    path: '/verify-email',
    name: 'auth.verify_email',
    beforeEnter: (to, from, next) =>
      store
        .dispatch('auth/verifyEmail', to.query.i)
        .then(() => next({ name: 'account.general' })),
  },

  {
    path: '/balance-hub',
    name: 'balance_hub',
    component: () => import('@/views/participant/BalanceHub.vue'),
    beforeEnter: async (to, from, next) => {
      try {
        await store.dispatch('auth/fetchParticipantBalance')
        return next()
      } catch (error) {
        notifier.error(error, {
          title: 'Problem loading balances. Please try again',
          duration: 0,
        })
        return next(false)
      }
    },
  },

  {
    path: '/data-collection-tool/batch/:batchId/task/:taskId',
    name: 'dc_tool_legacy',
    beforeEnter: async (to, from, next) => {
      const activeStudyId = store.getters['auth/activeStudyId']

      if (!activeStudyId) {
        return next({ name: 'error.404' })
      }

      const study = store.getters['participant/studies/denormalized']

      const isDcToolStudy =
        study.data_collection_method === DATA_COLLECTION_TASK_BUILDER

      const isBatchIdMatched = study.data_collection_id === to.params.batchId

      if (!isDcToolStudy || !isBatchIdMatched) {
        return next({ name: 'error.404' })
      }

      await store.dispatch('participant/dataCollectionTool/fetchTask', {
        batchId: to.params.batchId,
        taskId: to.params.taskId,
      })

      return next()
    },
    component: () => import('@/views/participant/DataCollectionTool.vue'),
    props: ({ params }) => ({
      batchId: params.batchId,
      taskId: params.taskId,
    }),
    meta: {
      hideNav: true,
    },
  },

  {
    path: '/data-collection-tool/batch/:batchId/task-group/:taskGroupId',
    name: 'dc_tool',
    beforeEnter: async (to, from, next) => {
      const activeStudyId = store.getters['auth/activeStudyId']

      if (!activeStudyId) {
        return next({ name: 'error.404' })
      }

      const study = store.getters['participant/studies/denormalized']

      const isDcToolStudy =
        study.data_collection_method === DATA_COLLECTION_TASK_BUILDER

      const isBatchIdMatched = study.data_collection_id === to.params.batchId

      if (!isDcToolStudy || !isBatchIdMatched) {
        return next({ name: 'error.404' })
      }

      await store.dispatch('participant/dataCollectionTool/fetchTaskGroup', {
        batchId: to.params.batchId,
        taskGroupId: to.params.taskGroupId,
      })

      return next()
    },
    component: () => import('@/views/participant/DataCollectionTool.vue'),
    props: ({ params }) => ({
      batchId: params.batchId,
      taskGroupId: params.taskGroupId,
    }),
    meta: {
      hideNav: true,
    },
  },

  {
    path: '/feature-under-development',
    name: 'feature-under-development',
    component: () => import('@/views/FeatureUnderDevelopment.vue'),
  },

  {
    path: '/404',
    name: 'error.404',
    component: () => import('@/views/error/Error.vue'),
    props: () => ({
      code: 404,
    }),
  },
  {
    path: '/500',
    name: 'error.500',
    component: () => import('@/views/error/Error.vue'),
    props: () => ({
      code: 500,
    }),
  },
  {
    path: '/429',
    name: 'error.429',
    component: () => import('@/views/error/Error.vue'),
    props: () => ({
      code: 429,
    }),
  },
  {
    path: '/429',
    name: 'error.participant-studies-rate-limited',
    component: () => import('@/views/error/Error.vue'),
    props: () => ({
      code: 429,
      description:
        "We've detected unusually high activity from your account. To keep things fair, your access to studies have been limited, please check back later.",
    }),
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: {
      name: 'error.404',
    },
  },
]
