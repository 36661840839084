import { trackStructEvent } from '@/integrations/snowplow'
import {
  Action,
  Category,
  Label,
  CATEGORY_TO_SCHEMA_MAP,
  DEFAULT_ACTION,
} from './constants'

export default ({
  category,
  action,
  label,
  metadata,
}: {
  category: Category
  action?: Action
  label: Label
  metadata: Record<string, unknown>
}) => {
  trackStructEvent({
    category,
    action: action ?? DEFAULT_ACTION,
    label,
    context: [{ schema: CATEGORY_TO_SCHEMA_MAP[category], data: metadata }],
  })
}
