<template>
  <div
    v-if="isRegistration"
    :class="[
      'panel',
      {
        'panel--participant': userType === 'Participant',
        'panel--researcher': userType === 'Researcher',
      },
    ]"
    data-testid="registration-panel"
  >
    <h2 class="panel__title">{{ t('title') }}</h2>
    <AccountProtectionContent :is-registration="true" @skip="handleSkip" />
  </div>
  <DSDialogPanel
    v-else
    v-bind="panelProps"
    :title="t('title')"
    class="panel panel--dialog-panel"
  >
    <AccountProtectionContent @close="handleCancel" />
  </DSDialogPanel>
</template>

<script setup lang="ts">
import { DSDialogPanel } from '@prolific-oss/design-system'
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'
import AccountProtectionContent from './AccountProtectionContent.vue'

const { t } = useI18n()
const store = useStore()

interface PanelProps {
  open: boolean
  onClose: () => void
}

const { panelProps, isRegistration } = defineProps<{
  panelProps?: PanelProps
  isRegistration?: boolean
}>()

const emits = defineEmits<{
  (e: 'close'): void
  (e: 'skip'): void
}>()

const userType = computed(() => store.getters['auth/user'].user_type)

const handleCancel = () => {
  emits('close')
}

const handleSkip = () => {
  emits('skip')
}
</script>

<style lang="scss" scoped>
.panel {
  border-radius: var(--space-2);

  .panel--dialog-panel {
    box-shadow: var(--box-shadow-light) !important;
  }

  @media screen and (width >= $md) {
    width: 75% !important;
  }
  @media screen and (width >= $lg) {
    width: 40% !important;
  }

  &__title {
    color: $text-color-primary;
    margin: 0;
    padding: $space-8 0;
    font-size: $font-size-2;
    font-weight: $font-weight-bold;
  }

  &--participant {
    box-shadow: var(--box-shadow-light) !important;
    background-color: var(--background-color-base);
    padding: $space-4 $space-5;

    @media screen and (width >= $md) {
      width: unset !important;
    }
    @media screen and (width >= $lg) {
      width: unset !important;
    }

    .panel__title {
      margin: 0;
      padding: 0 0 $space-5 0;
      font-size: $font-size-5;
      font-weight: $font-weight-bold;
      color: $text-color-base;
    }
  }
}
</style>

<i18n lang="yaml">
en:
  title: 'Protect your account'
</i18n>
