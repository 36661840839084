import { featureIsEnabled } from '@/integrations/launchDarkly'
import {
  FLAG_DCT_AI_TASK_BUILDER,
  FLAG_DCT_AI_TASK_BUILDER_RESEARCHER_UI,
} from '@/integrations/launchDarkly/active-flags'
import store from '@/store'

export default [
  {
    path: 'researcher/workspaces/:workspaceId/ai-task-builder',
    name: 'workspaces.ai-task-builder',
    component: () =>
      import('@/views/researcher/workspaces/AITaskBuilderDashboard.vue'),
    async beforeEnter(to, from, next) {
      // can't use requiresLDFlag helper on workspace-targeted flags yet
      if (
        !featureIsEnabled(FLAG_DCT_AI_TASK_BUILDER) ||
        !featureIsEnabled(FLAG_DCT_AI_TASK_BUILDER_RESEARCHER_UI)
      ) {
        return next({ name: 'error.404' })
      }

      await store.dispatch('researcher/aiTaskBuilder/fetchBatches')
      if (store.state.researcher.aiTaskBuilder.batches.length === 0) {
        return next({
          ...to,
          name: 'workspaces.ai-task-builder.new',
          replace: true,
        })
      }

      next()
    },
  },
  {
    path: 'researcher/workspaces/:workspaceId/ai-task-builder/new',
    name: 'workspaces.ai-task-builder.new',
    component: () =>
      import('@/views/researcher/workspaces/AITaskBuilderUpload.vue'),
    beforeEnter(to, from, next) {
      // can't use requiresLDFlag helper on workspace-targeted flags yet
      if (
        !featureIsEnabled(FLAG_DCT_AI_TASK_BUILDER) ||
        !featureIsEnabled(FLAG_DCT_AI_TASK_BUILDER_RESEARCHER_UI)
      ) {
        return next({ name: 'error.404' })
      }

      next()
    },
  },
]
