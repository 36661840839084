import { isAxiosError } from 'axios'
import type { ActionContext, Commit, Dispatch } from 'vuex'
import { endpoints } from '@/api'
import { getAuthHttpClient } from '@/api/auth'
import { translate } from '@/i18n'
import notifier from '@/utils/notifier'
import type { Auth0Id } from '../../types'
import { removeAuth0IdPrefix } from '../helpers'
import type { MfaChallengeBody, MFAEnrollment, State } from './types'

export const SET_MFA_ENROLLMENTS = 'SET_MFA_ENROLLMENTS'
export const SET_MFA_ENROLLMENTS_LOADING = 'SET_MFA_ENROLLMENTS_LOADING'
export const SET_MFA_TOKEN = 'SET_MFA_TOKEN'
export const SET_MFA_ENROLLMENTS_DELETE = 'SET_MFA_ENROLLMENTS_DELETE'

const state: State = {
  mfaEnrollments: [],
  mfaEnrollmentsLoading: true,
  mfaEnrollmentsDeleting: false,
  mfaToken: null,
}

const mutations = {
  [SET_MFA_ENROLLMENTS](state: State, mfaEnrollments: MFAEnrollment[]) {
    state.mfaEnrollments = mfaEnrollments
  },

  [SET_MFA_ENROLLMENTS_LOADING](state: State, loading: boolean) {
    state.mfaEnrollmentsLoading = loading
  },

  [SET_MFA_ENROLLMENTS_DELETE](state: State, deleting: boolean) {
    state.mfaEnrollmentsDeleting = deleting
  },

  [SET_MFA_TOKEN](state: State, token: string | null) {
    state.mfaToken = token
  },
}

const actions = {
  setMfaEnrollmentsLoading({ commit }: { commit: Commit }, loading: boolean) {
    commit(SET_MFA_ENROLLMENTS_LOADING, loading)
  },

  async fetchMfaEnrollments(
    { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
    data: { accessToken: string; sub: string }
  ) {
    try {
      await dispatch('setMfaEnrollmentsLoading', true)
      const { accessToken, sub } = data
      const oidcUserId = removeAuth0IdPrefix(sub as Auth0Id)
      const client = getAuthHttpClient(accessToken)
      const url = endpoints['new-auth'].MFA_ENROLLMENTS(oidcUserId)
      const { data: mfaEnrollments } = await client.get(url, {
        useGlobalErrorHandling: false,
      })

      commit(SET_MFA_ENROLLMENTS, mfaEnrollments)
      await dispatch('setMfaEnrollmentsLoading', false)
      return mfaEnrollments
    } catch (error) {
      await dispatch('setMfaEnrollmentsLoading', false)

      if (isAxiosError(error)) {
        commit(SET_MFA_ENROLLMENTS, [])
        return false
      }

      throw error
    }
  },

  retrieveMfaToken(
    { commit, rootGetters }: ActionContext<State, unknown>,
    { password }: { password: string }
  ) {
    const unprexiedAuth0Id = removeAuth0IdPrefix(
      rootGetters['oidc/oidcUser'].sub
    )
    return getAuthHttpClient(rootGetters['oidc/oidcAccessToken'])
      .post(endpoints['new-auth'].CONFIRM_PASSWORD(unprexiedAuth0Id), {
        email: rootGetters['auth/user'].email,
        password,
      })
      .then(({ data }: { data: { mfa_token: string } }) =>
        commit(SET_MFA_TOKEN, data.mfa_token)
      )
      .catch((error: unknown) => {
        commit(SET_MFA_TOKEN, null)
        if (isAxiosError(error)) {
          notifier.error(translate('notifications.confirm_password_error'), {
            duration: 10000,
          })
          return
        }
        throw error
      })
  },

  async isMfaChallengeValid(
    { getters, rootGetters }: ActionContext<State, unknown>,
    { code, type }: { code: string; type: 'otp_code' | 'recovery_code' }
  ): Promise<boolean | void> {
    const unprexiedAuth0Id = removeAuth0IdPrefix(
      rootGetters['oidc/oidcUser'].sub
    )
    const mfaToken = getters.mfaToken

    const body: MfaChallengeBody = {
      mfa_token: mfaToken,
    }
    body[type] = code

    try {
      await getAuthHttpClient(rootGetters['oidc/oidcAccessToken']).post(
        endpoints['new-auth'].CONFIRM_MFA_CHALLENGE(unprexiedAuth0Id),
        body,
        {
          useGlobalErrorHandling: false,
        }
      )

      return true
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        if (error.response?.status === 403) {
          return false
        }
      }
      throw error
    }
  },

  async resetMfaEnrollments({
    dispatch,
    commit,
    rootGetters,
  }: ActionContext<State, unknown>) {
    try {
      await dispatch('setMfaEnrollmentsDeleting', true)
      const unprexiedAuth0Id = removeAuth0IdPrefix(
        rootGetters['oidc/oidcUser'].sub
      )
      const client = getAuthHttpClient(rootGetters['oidc/oidcAccessToken'])
      const url = endpoints['new-auth'].MFA_ENROLLMENTS(unprexiedAuth0Id)
      await client.delete(url, {
        useGlobalErrorHandling: false,
      })

      commit(SET_MFA_ENROLLMENTS, [])
      await dispatch('setMfaEnrollmentsDeleting', false)
      notifier.success(translate('notifications.mfa.removed_description'), {
        title: translate('notifications.mfa.removed_title'),
      })
    } catch (error) {
      await dispatch('setMfaEnrollmentsDeleting', false)
      notifier.error(translate('notifications.general_error'))

      if (isAxiosError(error)) {
        return
      }
      throw error
    }
  },
}

const getters = {
  mfaEnrollments: ({ mfaEnrollments }: State) => mfaEnrollments,
  mfaEnrollmentsLoading: ({ mfaEnrollmentsLoading }: State) =>
    mfaEnrollmentsLoading,
  mfaEnrollmentsDeleting: ({ mfaEnrollmentsDeleting }: State) =>
    mfaEnrollmentsDeleting,
  mfaToken: ({ mfaToken }: State) => mfaToken,
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
