import { datadogRum } from '@datadog/browser-rum'
import jwtDecode from 'jwt-decode'
import { User, type UserSettings } from 'oidc-client'
import { endpoints, http } from '@/api'
import type { Currency, ReferrerCoupon } from '@/api/types'
import gtag from '@/integrations/gtag'
import rollbar from '@/integrations/rollbar'
import router from '@/router'
import store from '@/store'
import type { Auth0TokenSet, Auth0Id } from '../types'

type CreateUserParams = {
  payload: Record<string, unknown>
  csrfToken: string
}

const logError = (errorMessage: string, error?: any) => {
  datadogRum.addError(error ?? errorMessage)
  rollbar.error(errorMessage, error)
}

export const handlePostRegistrationLogin = async (tokens: Auth0TokenSet) => {
  const oidc_user = {
    ...tokens,
    expires_at: 0,
    profile: {},
  }

  try {
    oidc_user.profile = jwtDecode(tokens?.id_token)
  } catch (error) {
    if (tokens?.id_token) {
      const errorMessage =
        error instanceof Error ? error.message : 'Unknown error'
      logError(
        `Failed to decode the JWT from the auth service: ${errorMessage}`,
        error
      )
    }
    await router.push({ path: '/' })
    return
  }

  try {
    // we need to get the actual time of expiry so that the oidc client can track when it'll expire and refresh the token
    const expiresAt = new Date()
    expiresAt.setSeconds(expiresAt.getSeconds() + tokens.expires_in)
    oidc_user.expires_at = expiresAt.getTime() / 1000
    await store.dispatch(
      'oidc/storeOidcUser',
      new User(oidc_user as unknown as UserSettings)
    )
  } catch (error) {
    // user has been created but is not logged in, so log the error and return them to the login page
    // note that this could also mean that the auth service returned a non-descriptive 400 error
    const errorMessage =
      error instanceof Error ? error.message : 'Unknown error'
    logError(`Failed to store user: ${errorMessage}`, error)
    await router.push({ path: '/' })
  }
}

export const handlePostRegistrationRedirect = () => {
  const activeRoute = sessionStorage.getItem('vuex_oidc_active_route')
  gtag.event('conversion', {
    send_to: 'AW-740080876/GGsNCMDBiagDEOz58uAC',
  })
  const redirect =
    activeRoute && activeRoute !== '/'
      ? { path: activeRoute }
      : { name: 'home' }

  return router.push(redirect)
}

export const createResearcher = async ({
  payload,
  csrfToken,
}: CreateUserParams) => {
  // tokens will be returned if Auth0 is enabled on the environment
  const resp = await http.post<{
    user: unknown
    tokens?: Auth0TokenSet
  }>(endpoints.auth.REGISTER_RESEARCHER, payload, {
    withCredentials: true,
    headers: {
      'X-CSRFTOKEN': csrfToken,
    },
  })

  return resp
}

export const updateDataForActiveRoute = <T extends { [key: string]: unknown }>(
  data: T
): T & { create_workspaces?: boolean } => {
  const activeRoute = sessionStorage.getItem('vuex_oidc_active_route')
  if (activeRoute?.match(/^\/researcher\/invitations\/.+$/)) {
    /**
     * RNC-594: If the researcher is registering via a workspace invitation
     * link, we need to forward a `create_workspaces: false` flag to BE
     * so that no default workspace will be created (because they are joining one)
     */
    return { ...data, create_workspaces: false }
  }

  return data
}

export const sumReferralCredits = (
  referrals: ReferrerCoupon[] = [],
  currency: Currency
) => {
  return referrals.reduce((acc, referral) => {
    const credit = referral.credit[currency]
    return acc + (credit?.referrer_credit ?? 0)
  }, 0)
}

export const removeAuth0IdPrefix = (oidcUserSub: Auth0Id): string => {
  const validPrefix = /^(auth0|google-oauth2)\|/

  if (!validPrefix.test(oidcUserSub)) {
    throw new Error(
      'Invalid ID format. Expected "auth0|{id}" or "google-oauth2|{id}".'
    )
  }

  return oidcUserSub.replace(validPrefix, '')
}
