import store from '@/store'
import { Category, Label } from './constants'
import trackEvent from './trackEvent'

export default (label: Label) =>
  trackEvent({
    category: Category.USER_SETTINGS,
    label,
    metadata: { userType: store.getters['auth/user'].user_type },
  })
