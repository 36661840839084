import parseISO from 'date-fns/parseISO'

/**
 * Compares 2 strings and orders them alphabetically
 */
export function sortByText(a, b) {
  if (a < b) {
    return -1
  }
  if (a > b) {
    return 1
  }
  return 0
}

/**
 * Compares 2 dates and orders them chronologically
 */
export function sortByDate(a, b) {
  const date_a = parseISO(a)
  const date_b = parseISO(b)
  return date_a - date_b
}

/**
 * Compares 2 numbers and orders them numerically
 */
export function sortByNumber(a, b) {
  return a - b
}
