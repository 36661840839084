import type { Module } from 'vuex'
import { http, endpoints } from '@/api'
import type { ParticipantGroup, ParticipantGroupParticipant } from '@/api/types'

export type State = {
  participantGroups: ParticipantGroup[]
  loadingParticipantGroups: boolean
  participantGroup: ParticipantGroup | null
  participantGroupParticipants: string[]
}

export type CreatePayload = Pick<
  ParticipantGroup,
  'name' | 'participant_ids' | 'description'
> &
  (
    | { project_id: string | null; workspace_id?: never }
    | { workspace_id: string | null; project_id?: never }
  )

export type UpdateParticipantsPayload = {
  participant_ids: string[]
}

const SET_PARTICIPANT_GROUPS = 'SET_PARTICIPANT_GROUPS'
const SET_LOADING_PARTICIPANT_GROUPS = 'SET_LOADING_PARTICIPANT_GROUPS'
const SET_PARTICIPANT_GROUP = 'SET_PARTICIPANT_GROUP'
const ADD_PARTICIPANT_GROUP = 'ADD_PARTICIPANT_GROUP'
const DELETE_PARTICIPANT_GROUP = 'DELETE_PARTICIPANT_GROUP'
const SET_PARTICIPANT_GROUP_PARTICIPANTS = 'SET_PARTICIPANT_GROUP_PARTICIPANTS'

export default {
  namespaced: true,

  state: {
    participantGroups: [],
    loadingParticipantGroups: false,
    participantGroup: null,
    participantGroupParticipants: [],
  } as State,

  mutations: {
    [SET_PARTICIPANT_GROUPS](state, participantGroups: ParticipantGroup[]) {
      state.participantGroups = participantGroups
    },

    [SET_LOADING_PARTICIPANT_GROUPS](state, loading: boolean) {
      state.loadingParticipantGroups = loading
    },

    [SET_PARTICIPANT_GROUP](state, participantGroup: ParticipantGroup) {
      state.participantGroup = participantGroup
    },

    [ADD_PARTICIPANT_GROUP](state, group) {
      state.participantGroups.push(group)
    },

    [DELETE_PARTICIPANT_GROUP](state, deletedGroupId) {
      const newGroups = state.participantGroups.filter(
        participantGroup => participantGroup.id !== deletedGroupId
      )
      state.participantGroups = newGroups
    },

    [SET_PARTICIPANT_GROUP_PARTICIPANTS](
      state,
      participantGroupParticipants: string[]
    ) {
      state.participantGroupParticipants = participantGroupParticipants
    },
  },

  actions: {
    setLoadingParticipantGroups({ commit }, loading: boolean) {
      commit(SET_LOADING_PARTICIPANT_GROUPS, loading)
    },

    async fetchParticipantGroups({ commit, dispatch, rootGetters }) {
      dispatch('setLoadingParticipantGroups', true)
      const participantGroups = await http.get<{ results: ParticipantGroup[] }>(
        endpoints.PARTICIPANT_GROUPS,
        {
          params: {
            workspace_id: rootGetters['researcher/workspaces/workspaceId'],
            active: 'true',
          },
        }
      )
      commit(SET_PARTICIPANT_GROUPS, participantGroups.results)
      dispatch('setLoadingParticipantGroups', false)
    },

    async fetchParticipantGroup(
      { commit },
      { participantGroupId }: { participantGroupId: ParticipantGroup['id'] }
    ) {
      const participantGroup = await http.get<ParticipantGroup>(
        endpoints.PARTICIPANT_GROUP(participantGroupId)
      )
      commit(SET_PARTICIPANT_GROUP, participantGroup)
    },

    async createParticipantGroup({ commit }, payload: CreatePayload) {
      const group = await http.post<ParticipantGroup, CreatePayload>(
        endpoints.PARTICIPANT_GROUPS,
        payload
      )
      commit(ADD_PARTICIPANT_GROUP, group)
      return group
    },

    async updateParticipantGroup(
      { commit },
      payload: Partial<ParticipantGroup> & { id: ParticipantGroup['id'] }
    ) {
      const { id, ...rest } = payload
      const group = await http.patch<
        ParticipantGroup,
        Partial<ParticipantGroup>
      >(endpoints.PARTICIPANT_GROUP(id), rest)
      commit(SET_PARTICIPANT_GROUP, group)
      return group
    },

    async deleteParticipantGroup(
      { commit },
      participantGroupId: ParticipantGroup['id']
    ) {
      await http.delete(endpoints.PARTICIPANT_GROUP(participantGroupId))
      commit(DELETE_PARTICIPANT_GROUP, participantGroupId)
    },

    async fetchParticipantGroupParticipants(
      { commit },
      { participantGroupId }: { participantGroupId: ParticipantGroup['id'] }
    ) {
      const participants = await http.get<{
        results: ParticipantGroupParticipant[]
      }>(endpoints.PARTICIPANT_GROUP_PARTICIPANTS(participantGroupId))
      const participantIds = participants.results.map(
        (p: ParticipantGroupParticipant) => p.participant_id
      )
      commit(SET_PARTICIPANT_GROUP_PARTICIPANTS, participantIds)
    },

    async addParticipantsToParticipantGroup(
      { commit, state },
      {
        participantGroupId,
        participantIds,
      }: {
        participantGroupId: ParticipantGroup['id']
        participantIds: string[]
      }
    ) {
      const participants = await http.post<
        {
          results: ParticipantGroupParticipant[]
        },
        UpdateParticipantsPayload
      >(endpoints.PARTICIPANT_GROUP_PARTICIPANTS(participantGroupId), {
        participant_ids: participantIds,
      })
      const newParticipantIds = participants.results.map(
        (p: ParticipantGroupParticipant) => p.participant_id
      )
      commit(SET_PARTICIPANT_GROUP_PARTICIPANTS, newParticipantIds)
      commit(SET_PARTICIPANT_GROUP, {
        ...state.participantGroup,
        participant_count: newParticipantIds.length,
      })
    },

    async removeParticipantsFromParticipantGroup(
      { commit, state },
      {
        participantGroupId,
        participantIds,
      }: {
        participantGroupId: ParticipantGroup['id']
        participantIds: string[]
      }
    ) {
      const participants = await http.delete<
        {
          results: ParticipantGroupParticipant[]
        },
        {
          data: UpdateParticipantsPayload
        }
      >(endpoints.PARTICIPANT_GROUP_PARTICIPANTS(participantGroupId), {
        data: {
          participant_ids: participantIds,
        },
      })
      const newParticipantIds = participants.results.map(
        (p: ParticipantGroupParticipant) => p.participant_id
      )
      commit(SET_PARTICIPANT_GROUP_PARTICIPANTS, newParticipantIds)
      commit(SET_PARTICIPANT_GROUP, {
        ...state.participantGroup,
        participant_count: newParticipantIds.length,
      })
    },
  },

  getters: {
    participantGroupById:
      ({ participantGroups }) =>
      (id: ParticipantGroup['id']) =>
        participantGroups.find(group => group.id === id) ?? null,
    participantGroups: ({ participantGroups }) => participantGroups,
    participantGroupParticipantCountById:
      ({ participantGroups }) =>
      (id: ParticipantGroup['id']) =>
        participantGroups.find(group => group.id === id)?.participant_count ??
        null,
  },
} satisfies Module<State, unknown>
