/* istanbul ignore file */
export default {
  auth: {
    FIREBASE_TOKEN: '/users/firebase/',
    VERIFY_EMAIL: token => `/verify-email/${token}/`,
    LINK_PAYPAL: '/users/link-paypal/',
    VERIFY_OAUTH2: '/auth/oa2verify/',
    RESET_PASSWORD: '/auth/password-reset/',
    REGISTER_RESEARCHER: '/auth/register/',
    REGISTER_PARTICIPANT: '/participants/register/',
    REGISTER_CAMPAIGN_USER: '/auth/campaigns/register/',
    RESET_PASSWORD_CONFIRM: token => `/auth/reset/${token}/`,
    UPDATE_PASSWORD: userId => `/users/${userId}/password-change/`,
    RESEND_EMAIL_VERIFICATION: '/resend-email-verification/',
    RESEND_CASHOUT_EMAIL_VERIFICATION: '/resend-cashout-email-verification/',
    VERIFY_PHONE: '/phone-verification/',
    VERIFY_PHONE_CODE: id => `/phone-verification/${id}/`,
    CASHOUT: id => `/users/${id}/cashout/`,
    INSTANT_CASHOUT: id => `/users/${id}/instant-cashout/`,
    COUNTRIES: '/countries/',
    CONFIRM_PASSWORD: '/auth/confirm-password/',
    SET_RESEARCHER_ONBOARDING: userId => `/users/${userId}/`,
    US_STATES: '/us-states/',
    WAITLIST: '/users/waitlist/',
    ID_VERIFICATION_TOKEN: '/id-verification-token/',
    ID_VERIFICATION_CHECK: '/id-verification-check/',
    ID_VERIFICATION_WORKFLOW_RUN_ID: '/onfido-run-id/',
    PARTICIPANT_BALANCE: userId => `/users/${userId}/balance/`,
    UPDATE_PARTICIPANT_ADDRESS: userId => `/users/${userId}/address/`,
    UPDATE_PARTICIPANT_DATE_OF_BIRTH: userId =>
      `/users/${userId}/date-of-birth/`,
    DISABLE_2_STEP: userId => `/auth/accounts/${userId}/email-2-step/disable/`,
  },
  'new-auth': {
    USERS: '/users',
    PASSWORD_RESET: '/password-reset/',
    MFA_ENROLLMENTS: userId => `/users/${userId}/enrollments/`,
    CREATE_ENROLLMENT_TICKET: userId => `/users/${userId}/enrollments/ticket/`,
    CONFIRM_PASSWORD: userId => `/users/${userId}/password/verifications/`,
    CONFIRM_MFA_CHALLENGE: userId =>
      `/users/${userId}/enrollments/verifications/`,
  },
  payments: {
    PARTICIPANT_CASHOUT_STATUS: userId => `/users/${userId}/cashout-status/`,
  },
  URL_CAMPAIGN: campaignCode => `/url-campaigns/${campaignCode}/`,
  MESSAGES: '/messages/',
  BULK_MESSAGE: '/messages/bulk/',
  MESSAGE_CHANNEL_ID_HASH: '/messages/channel-id/',
  USERS: userId => `/users/${userId}/`,
  STUDIES: '/studies/',
  STUDIES_PARTICIPANT: '/participant/studies/',
  STUDY: studyId => `/studies/${studyId}/`,
  STUDY_PARTICIPANT: studyId => `/participant/studies/${studyId}/`,
  STUDY_ONBOARDING: `/onboarding-studies/`,
  SUBMIT_STUDY_ONBOARDING_STUDY: `/onboarding-studies/submit/`,
  STUDY_FEEDBACK: studyId => `/studies/${studyId}/feedback/`,
  STUDY_COST: '/study-cost-calculator/',
  STUDY_COST_BY_ID: studyId => `/study-cost-calculator/${studyId}/`,
  STUDY_QUOTE: '/studies/quote/',
  ELIGIBLE_PARTICIPANT_COUNT: '/eligibility-count/',
  ELIGIBILITY_REQUIREMENTS_PUBLIC: '/custom-prescreeners/',
  ELIGIBLE_PARTICIPANT_COUNT_PUBLIC: '/eligible-participants/',
  FILTERS: '/filters/',
  FILTER_SETS: '/filter-sets/',
  FILTER_SET: id => `/filter-sets/${id}/`,
  STUDY_ALLOWLIST: studyId => `/studies/${studyId}/allowlist/`,
  STRATIFICATION: '/stratification/',
  DISMISS_STUDY: studyId => `/studies/${studyId}/dismiss/`,
  STUDY_ACTION: studyId => `/studies/${studyId}/transition/`,
  STUDY_RATING: '/study-ratings/',
  DUPLICATE_STUDY: studyId => `/studies/${studyId}/clone/`,
  SUBMISSIONS: '/submissions/',
  SUBMISSION: submissionId => `/submissions/${submissionId}/`,
  SUBMISSIONS_COUNT: queryString => `/submissions/count/${queryString}/`,
  STUDY_SUBMISSIONS: studyId => `/studies/${studyId}/submissions/`,
  STUDY_SUBMISSIONS_COUNTS: queryString =>
    `/studies/${queryString}/submissions/counts/`,
  SUBMISSIONS_RESERVE: '/submissions/reserve/',
  SUBMISSION_ACTION: submissionId => `/submissions/${submissionId}/transition/`,
  SUBMISSION_RETURN: submissionId =>
    `/submissions/${submissionId}/request-return/`,
  SCREEN_OUT_PAYMENT_CALCULATION: studyId =>
    `/studies/${studyId}/calculate-screen-out-payment/`,
  BULK_SCREEN_OUT: studyId => `/studies/${studyId}/screen-out-submissions/`,
  SUBMISSION_COMPLETE: '/submissions/complete/',
  APPROVE_SUBMISSIONS: '/submissions/bulk-approve/',
  EXPORT_SUBMISSIONS: studyId => `/studies/${studyId}/export/`,
  EXPORT_PARTICIPANT_SUBMISSIONS: '/submissions/export/',
  ADJUSTMENT_PAYMENTS_CALCULATOR: studyId =>
    `/studies/${studyId}/adjustment-payments-calculator/`,
  ADJUSTMENT_PAYMENTS: studyId => `/studies/${studyId}/adjustment-payments/`,
  BONUS_PAYMENTS: '/submissions/bonus-payments/',
  BONUS_PAYMENTS_PAY: id => `/bulk-bonus-payments/${id}/pay/`,
  BRAINTREE_TOKEN: '/users/braintree/',
  PAYMENT_METHODS: id => `/users/${id}/payment-methods/`,
  DELETE_PAYMENT_METHOD: (id, token) =>
    `/users/${id}/payment-methods/${token}/`,
  PAYMENT_METHOD_NONCE: (id, token) =>
    `/users/${id}/payment-methods/${token}/nonce/`,
  TOPUP: userId => `/users/${userId}/topup/`,
  BANK_TRANSFER: '/bank-transfer/',
  LOCAL_BANK_TRANSFERS: '/local-bank-transfers/',
  LOCAL_BANK_TRANSFERS_CURRENCIES: '/local-bank-transfers/currencies/',
  CONVERT_LOCAL_BANK_TRANSFER_AMOUNT: '/local-bank-transfers/convert/',
  INVOICES: id => `/users/${id}/invoices/`,
  INVOICE: studyId => `/studies/${studyId}/invoice/`,
  DOWNLOAD_INVOICE: (userId, invoiceId) =>
    `/users/${userId}/invoices-download/${invoiceId}/`,
  DOWNLOAD_PAYMENTS_INVOICE: invoiceId => `/invoices/${invoiceId}/download/`,
  EMAIL_INVOICE: invoiceId => `/invoices/${invoiceId}/send/`,
  STRIPE_INVOICES: `/stripe-invoices/`,
  CREATE_INVOICES: `/invoices/request/`,
  QUOTE: quoteId => `/invoices/${quoteId}/`,
  QUESTIONS: '/prescreening/',
  ANSWERS: '/prescreening-responses/',
  QUESTION: questionId => `/questions/${questionId}/`,
  RESEARCHER_REFERRAL_CAMPAIGN: userId => `/users/${userId}/referral-campaign/`,
  // TODO: the conditional is just less of a pain with the tests, need to figure out a more flexible way to test these
  RESEARCHER_REFERRALS: (userId, includeClaimed = false) =>
    includeClaimed
      ? `/users/${userId}/referrals/?include_claimed=${includeClaimed}`
      : `/users/${userId}/referrals/`,
  RESEARCHER_REFERRAL_CLAIM: (userId, referralId) =>
    `/users/${userId}/referrals/${referralId}/claim/`,
  CONTACT: '/contact/',
  CONTACT_FORM: id => `/contact/${id}/`,
  CONTACT_CREATE: id => `/contact/${id}/create/`,
  FINGERPRINT: '/statistics/',
  VALIDATE_PARTICIPANT_IDS: '/users/validate-participant-ids/',
  PARTICIPANT_UNVERIFY: userId => `/participants/${userId}/unverify/`,
  WORKSPACES: '/workspaces/',
  WORKSPACE: workspaceId => `/workspaces/${workspaceId}/`,
  WORKSPACE_USER: (workspaceId, userId) =>
    `/workspaces/${workspaceId}/users/${userId}/`,
  WALLET: walletId => `/wallets/${walletId}/`,
  WALLET_INVOICES: walletId => `/wallets/${walletId}/invoices/`,
  WALLET_DOWNLOAD_INVOICE: (walletId, invoiceId) =>
    `/wallets/${walletId}/invoices-download/${invoiceId}/`,
  WALLET_REFUND: walletId => `/wallets/${walletId}/refund/`,
  PROJECTS: workspaceId => `/workspaces/${workspaceId}/projects/`,
  PROJECT: projectId => `/projects/${projectId}/`,
  PROJECT_STUDIES: projectId => `/projects/${projectId}/studies/`,
  INVITATIONS: '/invitations/',
  INVITATIONS_ACCEPT: token => `/invitations/${token}/accept/`,
  INVITATIONS_WORKSPACE: workspaceId =>
    `/workspaces/${workspaceId}/invitations/`,
  WAITLIST_VERIFY_EMAIL: token => `/users/waitlist/verify-email/${token}/`,
  WORKSPACE_USER_PERMISSIONS: workspaceId =>
    `/workspaces/${workspaceId}/permissions/`,
  TOKENS: '/tokens/',
  TOKENS_DELETE: token => `/tokens/${token}/`,
  PARTICIPANTS_QUESTIONS_NEXT: (participantId, questionCount = 0) =>
    questionCount > 0
      ? `/participants/${participantId}/questions/next/?limit=${questionCount}`
      : `/participants/${participantId}/questions/next/`,

  PARTICIPANT_QUESTION_SKIP: (participantId, questionId) =>
    `/participants/${participantId}/questions/${questionId}/skip/`,
  REJECTION_REASONS: '/submissions/rejection-reasons/',
  SURVEYS: '/surveys/',
  SURVEY: surveyId => `/surveys/${surveyId}`,
  SURVEY_RESPONSES: surveyId => `/surveys/${surveyId}/responses/`,
  SURVEY_RESPONSES_SUMMARY: surveyId =>
    `/surveys/${surveyId}/responses/summary/`,
  REALLOCATE: studyId => `/studies/${studyId}/transition/reallocate/`,
  REALLOCATION_PREVIEW: studyId => `/studies/${studyId}/reallocation-preview/`,
  PARTICIPANT_GROUPS: '/participant-groups/',
  PARTICIPANT_GROUP: id => `/participant-groups/${id}/`,
  PARTICIPANT_GROUP_PARTICIPANTS: id =>
    `/participant-groups/${id}/participants/`,
  BLOCK_RESEARCHER: researcherId => `/block-researcher/${researcherId}/`,
  REACTIVATION_COUPON: '/reactivation-coupon-redemption/',
  ASSIGN_COUPON: '/assign-coupon/',
  STUDY_CREDENTIALS: studyId => `/studies/${studyId}/credentials`,
  CREDENTIAL_POOLS: credentialPoolId => `/credentials/${credentialPoolId}`,
  CAMPAIGNS: '/campaigns/',
  CAMPAIGN: campaignId => `/campaigns/${campaignId}/`,
  CAMPAIGN_STOP: campaignId => `/campaigns/${campaignId}/stop/`,
  REFERRAL_CAMPAIGN: campaignId => `/campaigns/referrals/${campaignId}/`,
  PARTICIPANT_REFERRAL_CAMPAIGN: `/campaigns/referrals/`,
  FREE_TRIAL_RESPONSES: (studyId, formId) =>
    `/typeform-trial-study/study/${studyId}/form/${formId}/responses/`,
  START_FREE_TRIAL_STUDY: '/studies/trial-study/',
  HAS_NONE_DRAFT_STUDIES: userId => `/users/${userId}/has-studies/`,
  ESTIMATED_RECRUITMENT_TIME: '/studies/predicted-recruitment-time/',
  DCTOOL_BATCHES_TASK: (batchId, taskId) =>
    `/data-collection/batches/${batchId}/task/${taskId}/`,
  DCTOOL_BATCHES_TASK_GROUP: (batchId, taskGroupId) =>
    `/data-collection/batches/${batchId}/task-group/${taskGroupId}/`,
  DCTOOL_BATCHES: '/data-collection/batches/',
  CLEARBIT_SEARCH: `/clearbit/search/`,
}
