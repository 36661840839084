import { computed } from 'vue'
import { useRoute, type RouteLocationNormalizedLoaded } from 'vue-router'
import { useStore } from 'vuex'
import {
  trackAccountProtectionBannerEvent,
  Label,
  trackUserMfaSettings,
} from '@/analytics/mfa'
import { StateManager } from '../utils'

const getRouteType = (route: RouteLocationNormalizedLoaded) => {
  if (!route) return 'default'
  if (route.path.includes('general')) return 'general'
  if (route.path.includes('register')) return 'register'
  return 'default'
}

export default () => {
  const store = useStore()
  const currentRoute = useRoute()
  const stateManager = new StateManager()
  const user = computed(() => store.getters['auth/user'])
  const trackEvent = (label: Label) => {
    const userType = user.value?.user_type

    const route = getRouteType(currentRoute)

    let totalDismissals

    switch (route) {
      case 'general':
        trackUserMfaSettings(Label.ENABLE)
        break
      default:
        ;({ count: totalDismissals } = stateManager.getState())
        trackAccountProtectionBannerEvent(label, { userType, totalDismissals })
        break
    }
  }

  return {
    trackEvent,
    Label,
  }
}
