<template>
  <div v-loading="true" class="callback" />
</template>

<!-- TODO: Migrate to <script setup lang="ts"> -->
<!-- eslint-disable vue/component-api-style -->
<!-- eslint-disable vue/block-lang -->
<script>
import { mapActions } from 'vuex'

export default {
  name: 'OidcCallback',

  created() {
    this.oidcSignInCallback()
      .then(redirectPath => {
        this.$router.push(redirectPath).catch(err => {})
      })
      .catch(error => {
        // pass the query params through so we can display any error messages auth0 provides
        this.$router.push({
          name: 'oidcCallbackError',
          query: this.$route.query,
        })
      })
  },

  methods: {
    ...mapActions('oidc', ['oidcSignInCallback']),
  },
}
</script>

<style lang="scss" scoped>
.callback {
  flex: 1;
}
</style>
