import { createRouter as newCreateRouter, createWebHistory } from 'vue-router'
import AuthenticatedLayout from '@/components/layout/Authenticated.vue'
import store from '@/store'
import { beforeEach, afterEach } from './hooks'
import routes from './routes'
import messages from './routes/messages'
import participant from './routes/participant'
import researcher from './routes/researcher'

const createRouter = () =>
  newCreateRouter({
    history: createWebHistory(),
    routes,
  })

const router = createRouter()

if (import.meta.env.NODE_ENV !== 'test') {
  router.beforeEach(beforeEach)
  router.afterEach(afterEach)
}

export function resetRouter() {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher
}

// used in unit tests & here
export function getUserTypeSpecificRoutes(userType) {
  return [
    {
      path: '/',
      component: AuthenticatedLayout,
      props: { userType },
      children: [
        ...messages,
        ...(userType === 'researcher' ? researcher : participant),
      ],
      async beforeEnter(to, from, next) {
        if (userType === 'researcher') {
          await store.dispatch(
            'researcher/workspaces/fetchWorkspaces',
            '?limit=50'
          )
          const workspaces = store.getters['researcher/workspaces/workspaces']

          const activeWorkspaceId =
            store.getters['researcher/workspaces/workspace']?.id

          const currentProjectId =
            store.getters['auth/user']?.current_project_id
          const workspaceId = workspaces?.[workspaces.length - 1]?.id

          const ids = [activeWorkspaceId, currentProjectId, workspaceId]

          for (const [index, id] of ids.entries()) {
            if (index === 1 && id) {
              await store.dispatch(
                'researcher/workspaces/fetchCurrentWorkspaceByProjectId',
                id
              )
              break
            }

            if (index !== 1 && id) {
              await store.dispatch('researcher/workspaces/fetchWorkspace', id)
              break
            }
          }
        }
        next()
      },
    },
  ]
}

export function addUserTypeSpecificRoutes(userType) {
  getUserTypeSpecificRoutes(userType).forEach(item => router.addRoute(item))
}

export default router
