import type { LDFlagSet } from 'launchdarkly-js-client-sdk'
// A place to specify fallback variations for LD flags.
// The values defined here will be the value of the flag when
// 1. the LD server cannot be reached or 2. there is no default
// targeting=OFF variation specified for this flag.
import {
  FLAG_MUA_PROJECT_LEVEL_ACCESS,
  FLAG_ES_DISABLE_CASHOUTS,
  FLAG_SCAL_REQUEST_3DS_CHALLENGE,
  FLAG_PAY_SKIP_3DS_VERIFICATION,
  FLAG_INC_HIDE_DATA_COLLECTION_OPTIONS,
  FLAG_HI_REP_SAMPLE_US_ENGLISH_AGE_POLITICAL_AFFILIATION,
  FLAG_SEA_REP_SAMPLE_UK_ENGLISH_AGE_POLITICAL_AFFILIATION,
  FLAG_HI_REP_SAMPLE_US_ENGLISH_AGE_POLITICAL_AFFILIATION_ETHNICITY,
  FLAG_SEA_REP_SAMPLE_UK_ENGLISH_AGE_POLITICAL_AFFILIATION_ETHNICITY,
  FLAG_INC_AI_WORKSPACE_LANGUAGE,
  FLAG_EARS_AUTH,
  FLAG_EARS_AUTH_COOKIE_CHECK,
  FLAG_INC_DATA_COLLECTION_CREDENTIALS,
  FLAG_INC_MESSAGES_MOBILE_IMPROVEMENTS,
  FLAG_EARS_MESSAGES_IN_MARKDOWN,
  FLAG_EARS_DELETE_WORKSPACE,
  FLAG_SEA_CUSTOM_QUOTA_SAMPLES,
  FLAG_SEA_CUSTOM_QUOTA_SAMPLES_DEFAULT_APPLY_SEX_BALANCED_QUOTA,
  FLAG_ES_MAINTENANCE_MODE,
  FLAG_IB_SUBMISSION_TIMEZONE,
  FLAG_IB_MONOCLE_ENABLED,
  FLAG_EARS_658_HIDE_PAYMENT_TIMING_MESSAGE_CATEGORY,
  FLAG_PSG_509_PII,
  FLAG_EARS_466_FREE_TRIAL_STUDY,
  FLAG_SEA_SCREEN_OUT_EXPERIMENT,
  FLAG_DCP_TASKFLOW,
  FLAG_IB_PEC_ERRORS_MODAL,
  FLAG_SEA_COLLAPSED_NAV,
  FLAG_SSG_REFERRAL_ELIGIBILITY,
  FLAG_SEA_SUMMARY_SIDE_PANEL,
  FLAG_IB_ONFIDO_STUDIO,
  FLAG_SSG_RESEARCHER_REGISTRATION_FLOW,
  FLAG_SEA_ENABLE_THROUGHPUT_OPTIMISATION,
  FLAG_SSG_HOME_PAGE,
  FLAG_SSG_ONBOARDING_STEPS,
  FLAG_SECT_381_MFA_PHASE_1,
  FLAG_SSG_MARKETING_SOFT_OPT_IN,
  FLAG_SSG_CLEARBIT_INTEGRATION,
  FLAG_PSG_870_NEW_AUDIENCE_SELECTION,
  FLAG_DCT_AI_TASK_BUILDER,
  FLAG_DCT_AI_TASK_BUILDER_RESEARCHER_UI,
  FLAG_PSG_811_FILTER_MIN_REWARD,
  FLAG_SSG_PRICE_CALCULATOR,
  FLAG_SECT_381_MFA_PHASE_1_PARTICIPANT_REGISTRATION,
  FLAG_SECT_381_MFA_PHASE_1_RESEARCHER_REGISTRATION,
  FLAG_SEA_VIRTUALIZE_PGS,
} from './active-flags'

const flags: LDFlagSet = {
  [FLAG_MUA_PROJECT_LEVEL_ACCESS]: false,
  [FLAG_ES_DISABLE_CASHOUTS]: false,
  [FLAG_SCAL_REQUEST_3DS_CHALLENGE]: false,
  [FLAG_PAY_SKIP_3DS_VERIFICATION]: false,
  [FLAG_INC_HIDE_DATA_COLLECTION_OPTIONS]: false,
  [FLAG_HI_REP_SAMPLE_US_ENGLISH_AGE_POLITICAL_AFFILIATION]: false,
  [FLAG_SEA_REP_SAMPLE_UK_ENGLISH_AGE_POLITICAL_AFFILIATION]: false,
  [FLAG_HI_REP_SAMPLE_US_ENGLISH_AGE_POLITICAL_AFFILIATION_ETHNICITY]: false,
  [FLAG_SEA_REP_SAMPLE_UK_ENGLISH_AGE_POLITICAL_AFFILIATION_ETHNICITY]: false,
  [FLAG_INC_AI_WORKSPACE_LANGUAGE]: false,
  [FLAG_EARS_AUTH]: false,
  [FLAG_EARS_AUTH_COOKIE_CHECK]: false,
  [FLAG_INC_DATA_COLLECTION_CREDENTIALS]: false,
  [FLAG_INC_MESSAGES_MOBILE_IMPROVEMENTS]: false,
  [FLAG_EARS_MESSAGES_IN_MARKDOWN]: false,
  [FLAG_EARS_DELETE_WORKSPACE]: false,
  [FLAG_SEA_CUSTOM_QUOTA_SAMPLES]: true,
  [FLAG_SEA_CUSTOM_QUOTA_SAMPLES_DEFAULT_APPLY_SEX_BALANCED_QUOTA]: true,
  [FLAG_ES_MAINTENANCE_MODE]: false,
  [FLAG_IB_SUBMISSION_TIMEZONE]: false,
  [FLAG_IB_MONOCLE_ENABLED]: false,
  [FLAG_EARS_658_HIDE_PAYMENT_TIMING_MESSAGE_CATEGORY]: false,
  [FLAG_PSG_509_PII]: false,
  [FLAG_EARS_466_FREE_TRIAL_STUDY]: false,
  [FLAG_SEA_SCREEN_OUT_EXPERIMENT]: false,
  [FLAG_DCP_TASKFLOW]: false,
  [FLAG_IB_PEC_ERRORS_MODAL]: false,
  [FLAG_SSG_REFERRAL_ELIGIBILITY]: false,
  [FLAG_SEA_COLLAPSED_NAV]: false,
  [FLAG_SEA_SUMMARY_SIDE_PANEL]: false,
  [FLAG_IB_ONFIDO_STUDIO]: false,
  [FLAG_SSG_RESEARCHER_REGISTRATION_FLOW]: false,
  [FLAG_SEA_ENABLE_THROUGHPUT_OPTIMISATION]: false,
  [FLAG_SSG_HOME_PAGE]: false,
  [FLAG_SSG_ONBOARDING_STEPS]: false,
  [FLAG_SECT_381_MFA_PHASE_1]: false,
  [FLAG_SSG_MARKETING_SOFT_OPT_IN]: false,
  [FLAG_SSG_CLEARBIT_INTEGRATION]: false,
  [FLAG_PSG_870_NEW_AUDIENCE_SELECTION]: false,
  [FLAG_DCT_AI_TASK_BUILDER]: false,
  [FLAG_DCT_AI_TASK_BUILDER_RESEARCHER_UI]: false,
  [FLAG_PSG_811_FILTER_MIN_REWARD]: false,
  [FLAG_SSG_PRICE_CALCULATOR]: false,
  [FLAG_SECT_381_MFA_PHASE_1_PARTICIPANT_REGISTRATION]: false,
  [FLAG_SECT_381_MFA_PHASE_1_RESEARCHER_REGISTRATION]: false,
  [FLAG_SEA_VIRTUALIZE_PGS]: false,
}

export default flags
