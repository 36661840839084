import type { Module } from 'vuex'
import { http, endpoints } from '@/api'
import type {
  FilterOptionDetailed,
  EligibilityCountRequestPayload,
  EligibilityCountResponsePayload,
  FilterSet,
  FilterSetWithCount,
  FilterRequirement,
} from '@/api/types'

export type State = {
  normalizedFilters: {
    [key: string]: FilterOptionDetailed
  }
  loadingFilters: boolean
  filterIds: string[]
  filterSets: FilterSet[]
  filterSet: FilterSetWithCount | null
  loadingFilterSets: boolean
  count: EligibilityCountResponsePayload['count'] | null
  quotaBreakdown: EligibilityCountResponsePayload['quota_breakdown'] | null
  isLoadingCount: boolean
}

const SET_FILTERS = 'SET_FILTERS'
const SET_LOADING_FILTERS = 'SET_LOADING_FILTERS'
const SET_FILTER_SETS = 'SET_FILTER_SETS'
const SET_FILTER_SET = 'SET_FILTER_SET'
const ADD_FILTER_SET = 'ADD_FILTER_SET'
const UPDATE_FILTER_SET = 'UPDATE_FILTER_SET'
const DELETE_FILTER_SET = 'DELETE_FILTER_SET'
const SET_LOADING_FILTER_SETS = 'SET_LOADING_FILTER_SETS'
const SET_IS_LOADING_COUNT = 'SET_IS_LOADING_COUNT'
const SET_COUNT = 'SET_COUNT'

export default {
  namespaced: true,

  state: {
    normalizedFilters: {},
    loadingFilters: false,
    filterIds: [],
    filterSets: [],
    filterSet: null,
    loadingFilterSets: false,
    count: null,
    quotaBreakdown: null,
    isLoadingCount: false,
  } as State,

  mutations: {
    [SET_FILTERS](state, filters: FilterOptionDetailed[]) {
      state.normalizedFilters = {}
      state.filterIds = []
      for (const filter of filters) {
        state.normalizedFilters[filter.filter_id] = filter
        state.filterIds.push(filter.filter_id)
      }
    },

    [SET_LOADING_FILTERS](state, loading: boolean) {
      state.loadingFilters = loading
    },

    [SET_FILTER_SETS](state, filterSets: FilterSet[]) {
      state.filterSets = filterSets
    },

    [SET_LOADING_FILTER_SETS](state, loading: boolean) {
      state.loadingFilterSets = loading
    },

    [SET_FILTER_SET](state, filterSet: FilterSetWithCount) {
      state.filterSet = filterSet
    },

    [ADD_FILTER_SET](state, filterSet: FilterSet) {
      state.filterSets.push(filterSet)
    },

    [UPDATE_FILTER_SET](state, filterSet: FilterSetWithCount) {
      state.filterSet = filterSet
      const { eligible_participant_count, ...fs } = filterSet
      const existingFilterSetIndex = state.filterSets.findIndex(
        existingFilterSet => existingFilterSet.id === fs.id
      )
      if (existingFilterSetIndex !== -1) {
        state.filterSets.splice(existingFilterSetIndex, 1, fs)
      }
    },

    [DELETE_FILTER_SET](state, deletedFilterSetId) {
      const newFilterSets = state.filterSets.filter(
        filterSet => filterSet.id !== deletedFilterSetId
      )
      state.filterSets = newFilterSets
    },

    [SET_COUNT](state, payload: EligibilityCountResponsePayload | null) {
      if (!payload) {
        state.count = null
        state.quotaBreakdown = null
        return
      }
      const { count, quota_breakdown: quotaBreakdown } = payload
      state.count = count
      state.quotaBreakdown = quotaBreakdown || null
    },

    [SET_IS_LOADING_COUNT](state, isLoading) {
      state.isLoadingCount = isLoading
    },
  },

  actions: {
    setLoadingFilters({ commit }, loading: boolean) {
      commit(SET_LOADING_FILTERS, loading)
    },

    async fetchFilters({ commit, dispatch, rootGetters }) {
      dispatch('setLoadingFilters', true)
      const filters = await http.get<{ results: FilterOptionDetailed[] }>(
        endpoints.FILTERS,
        {
          params: {
            detailed: true,
            workspace_id: rootGetters['researcher/workspaces/workspaceId'],
          },
        }
      )
      commit(SET_FILTERS, filters.results)
      dispatch('setLoadingFilters', false)
    },

    setLoadingFilterSets({ commit }, loading: boolean) {
      commit(SET_LOADING_FILTER_SETS, loading)
    },

    fetchFilterSets({ commit, dispatch }, workspaceId: string) {
      dispatch('setLoadingFilterSets', true)
      return http
        .get<{ results: FilterSet[] }>(endpoints.FILTER_SETS, {
          params: { workspace_id: workspaceId, active: 'true' },
        })
        .then(({ results }) => {
          commit(SET_FILTER_SETS, results)
          dispatch('setLoadingFilterSets', false)
        })
        .catch(() => {
          dispatch('setLoadingFilterSets', false)
        })
    },

    fetchFilterSet(
      { commit },
      {
        filterSetId,
        filterSetVersionNumber,
      }: {
        filterSetId: FilterSet['id']
        filterSetVersionNumber?: FilterSet['version']
      }
    ) {
      return http
        .get<FilterSetWithCount>(endpoints.FILTER_SET(filterSetId), {
          params: { version_number: filterSetVersionNumber },
        })
        .then(filterSet => {
          commit(SET_FILTER_SET, filterSet)
          return filterSet
        })
        .catch(() => {})
    },

    async createFilterSet(
      { commit },
      {
        workspace_id,
        name,
        filters,
      }: Pick<FilterSet, 'name' | 'workspace_id' | 'filters'>
    ) {
      const filterSet = await http.post(endpoints.FILTER_SETS, {
        workspace_id,
        name,
        filters,
      })
      commit(ADD_FILTER_SET, filterSet)
      return filterSet
    },

    async updateFilterSet(
      { commit },
      { id, name, filters }: Pick<FilterSet, 'id' | 'name' | 'filters'>
    ) {
      const filterSet = await http.patch<FilterSetWithCount>(
        endpoints.FILTER_SET(id),
        {
          name,
          filters,
        }
      )

      commit(UPDATE_FILTER_SET, filterSet)
      return filterSet
    },

    async deleteFilterSet({ commit }, filterSetId: FilterSet['id']) {
      await http.delete(endpoints.FILTER_SET(filterSetId))

      commit(DELETE_FILTER_SET, filterSetId)
    },

    async fetchCount(
      { commit, rootGetters },
      {
        study_type,
        filters,
      }: Pick<EligibilityCountRequestPayload, 'study_type' | 'filters'>
    ) {
      const payload: EligibilityCountRequestPayload = {
        study_type,
        filters,
        workspace_id: rootGetters['researcher/workspaces/workspaceId'],
      }
      // reset count
      commit(SET_COUNT, null)
      commit(SET_IS_LOADING_COUNT, true)
      try {
        const response = await http.post<EligibilityCountResponsePayload>(
          endpoints.ELIGIBLE_PARTICIPANT_COUNT,
          payload
        )
        commit(SET_COUNT, response)
      } finally {
        commit(SET_IS_LOADING_COUNT, false)
      }
    },

    fetchCountForFilter(
      // This fetches the available count for a specific filter, given a set of answers
      { rootGetters },
      filter: FilterRequirement
    ) {
      return http.post<EligibilityCountResponsePayload>(
        endpoints.ELIGIBLE_PARTICIPANT_COUNT,
        {
          workspace_id: rootGetters['researcher/workspaces/workspaceId'],
          study_type: 'SINGLE',
          filters: [filter],
        }
      )
    },
  },

  getters: {
    filters: ({ normalizedFilters, filterIds }) => {
      const filters: FilterOptionDetailed[] = []
      for (const id of filterIds) {
        const filter = normalizedFilters[id]
        if (filter) {
          filters.push(filter)
        }
      }
      return filters
    },

    normalizedFilters: ({ normalizedFilters }) => normalizedFilters,

    getFilterById:
      ({ normalizedFilters }) =>
      (id: string) =>
        normalizedFilters[id],

    getFilterSetById:
      ({ filterSets }) =>
      (id: string) =>
        filterSets.find(filterSet => filterSet.id === id) ?? null,
  },
} satisfies Module<State, unknown>
