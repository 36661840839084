import type { Module } from 'vuex'
import { http, endpoints } from '@/api'
import type { Batch } from '@/api/types'

export type State = {
  batches: Batch[]
}

const SET_BATCHES = 'SET_BATCHES'

export default {
  namespaced: true,

  state: {
    batches: [],
  } as State,

  mutations: {
    [SET_BATCHES](state, batches: Batch[]) {
      state.batches = batches
    },
  },

  actions: {
    async fetchBatches({ commit, rootGetters }) {
      const batches = await http.get<{ results: Batch[] }>(
        endpoints.DCTOOL_BATCHES,
        {
          params: {
            workspace_id: rootGetters['researcher/workspaces/workspaceId'],
          },
        }
      )
      commit(SET_BATCHES, batches)
    },
  },

  getters: {
    batches: ({ batches }) => batches,
  },
} satisfies Module<State, unknown>
