import { formatDuration, intervalToDuration, isAfter, parseISO } from 'date-fns'
import isEmpty from 'lodash/isEmpty'
import throttle from 'lodash/throttle'
import { trackLogin, trackAccountEvent } from '@/analytics/common'
import { RESEARCHER_APP_INITIAL_LOAD_START } from '@/analytics/events'
import { endpoints, http } from '@/api'
import {
  COUNTRY_OF_RESIDENCE_FILTER_ID,
  CURRENT_COUNTRY_OF_RESIDENCE_FILTER_ID,
  GLOBAL_REP_SAMPLE_COUNTRIES,
  SUPPORTED_HYPERWALLET_COUNTRIES,
} from '@/constants'
import { translate } from '@/i18n'
import gtag from '@/integrations/gtag'
import hubspot from '@/integrations/hubspot'
import { setLDUser } from '@/integrations/launchDarkly'
import pendo, { initialisePendo } from '@/integrations/pendo'
import pusher from '@/integrations/pusher'
import rollbar from '@/integrations/rollbar'
import rum from '@/integrations/rum'
import { setUserContext, snowplow } from '@/integrations/snowplow'
import router, { addUserTypeSpecificRoutes } from '@/router'
import {
  codeToSymbol,
  formatMoney,
  formatMoneyWithCurrencySymbol,
} from '@/utils'
import { isNewAuthEnabled } from '@/utils/auth'
import download from '@/utils/download'
import {
  isValidAddressLine,
  isValidCity,
  isValidName,
  isValidPostalCode,
  isValidStateProvince,
} from '@/utils/hyperwalletValidators'
import notifier from '@/utils/notifier'
import { getParticipantBalanceInGBP } from '@/utils/payments'
import { getFingerprint } from '../../../utils/fingerprint'
import {
  createResearcher,
  updateDataForActiveRoute,
  handlePostRegistrationLogin,
  handlePostRegistrationRedirect,
  sumReferralCredits,
} from './helpers'
import mfa from './mfa'

export const types = Object.fromEntries(
  [
    'SET_TOKEN',
    'SET_USER',
    'SET_BT_TOKEN',
    'SET_SAVED_PAYMENT_METHODS',
    'SET_REFERRAL_CAMPAIGN',
    'SET_RESEARCHER_REFERRALS',
    'SET_RESEARCHER_TYPE',
    'SET_INVOICES',
    'UPDATE_BALANCE',
    'UPDATE_USER',
    'ADD_ACTIVE_ONGOING_STUDY',
    'REMOVE_ACTIVE_ONGOING_STUDY',
    'SUBSCRIBE_USER',
    'INCREMENT_UNANSWERED_QUESTION_COUNT',
    'DECREMENT_UNANSWERED_QUESTION_COUNT',
    'SET_COUNTRIES',
    'SET_PA_ENABLED',
    'SET_US_STATES',
    'SET_LAUNCHDARKLY_SET_USER_PROMISE',
    'SAVE_WAITLIST_DATA',
    'SET_LOCAL_CURRENCY_DETAILS',
    'SET_PARTICIPANT_ESTIMATED_TOTAL_BALANCE',
    'SET_PARTICIPANT_ESTIMATED_TOTAL_PENDING_BALANCE',
    'SET_PARTICIPANT_BALANCE',
    'SET_PENDING_BALANCE',
    'SET_PARTICIPANT_ADDRESS',
    'SET_PARTICIPANT_DATE_OF_BIRTH',
    'TRIGGER_VERIFICATION_DIALOG',
  ].map(type => [type, type])
)

const pusherEvents = {
  UPDATE: 'UPDATE',
}

const state = {
  user: {},
  token: null,
  bt_token: null,
  savedPaymentMethods: null,
  pusherChannels: {},
  referralCampaign: {},
  researcherReferrals: {},
  invoices: [],
  countries: {},
  USStates: [],
  launchDarklySetUserPromise: Promise.resolve(),
  waitlistData: {},
  multicurrency_enabled: false,
  triggerVerificationDialog: false,
}

const mutations = {
  [types.SET_TOKEN](state, value) {
    state.token = value
  },

  [types.SET_USER](state, value) {
    state.user = value
  },

  [types.SET_BT_TOKEN](state, value) {
    state.bt_token = value
  },

  [types.SET_SAVED_PAYMENT_METHODS](state, value) {
    state.savedPaymentMethods = value
  },

  [types.SET_REFERRAL_CAMPAIGN](state, referralCampaign) {
    state.referralCampaign = referralCampaign
  },

  [types.SET_RESEARCHER_REFERRALS](state, referrals) {
    state.researcherReferrals = referrals
  },

  [types.SET_RESEARCHER_TYPE](state, researcherType) {
    state.user.researcher_type = researcherType
  },

  [types.SET_INVOICES](state, invoices) {
    state.invoices = invoices
  },

  [types.UPDATE_BALANCE](state, { balance, available_balance }) {
    if (balance) {
      state.user.balance = balance
    }
    if (available_balance) {
      state.user.available_balance = available_balance
    }
  },

  [types.UPDATE_USER](state, payload) {
    Object.assign(state.user, payload)
  },

  // add to an array of active ongoing study ids
  [types.ADD_ACTIVE_ONGOING_STUDY](state, ongoingStudyId) {
    state.user.active_ongoing_study_ids?.push(ongoingStudyId)
  },

  // remove from an array of active ongoing study ids
  [types.REMOVE_ACTIVE_ONGOING_STUDY](state, ongoingStudyId) {
    state.user.active_ongoing_study_ids =
      state.user.active_ongoing_study_ids?.filter(id => id !== ongoingStudyId)
  },

  [types.SUBSCRIBE_USER](state, userId) {
    state.pusherChannels[userId] = true
  },

  [types.TRIGGER_VERIFICATION_DIALOG](state, value) {
    state.triggerVerificationDialog = value
  },

  [types.INCREMENT_UNANSWERED_QUESTION_COUNT](state) {
    state.user.unanswered_question_count =
      (state.user.unanswered_question_count || 0) + 1
  },

  [types.DECREMENT_UNANSWERED_QUESTION_COUNT](state) {
    if (
      state.user.unanswered_question_count &&
      state.user.unanswered_question_count > 0
    ) {
      state.user.unanswered_question_count -= 1
    }
  },

  [types.SET_COUNTRIES](state, countries) {
    state.countries = countries
  },

  [types.SET_PA_ENABLED](state, enabled) {
    state.user['pa_enabled'] = enabled
  },

  [types.SET_US_STATES](state, USStates) {
    state.USStates = USStates
  },

  [types.SET_LAUNCHDARKLY_SET_USER_PROMISE](state, promise) {
    state.launchDarklySetUserPromise = promise
  },

  [types.SAVE_WAITLIST_DATA](state, data) {
    state.waitlistData = data
  },

  [types.SET_LOCAL_CURRENCY_DETAILS](state, details) {
    state.localCurrencyCode = details.code
    state.conversionRate = details.rate
    state.currencyDecimalPlaces = details.decimal_places
  },

  [types.SET_PARTICIPANT_ESTIMATED_TOTAL_BALANCE](state, balance) {
    state['participant_estimated_total_balance'] = balance
  },

  [types.SET_PARTICIPANT_ESTIMATED_TOTAL_PENDING_BALANCE](state, balance) {
    state['participant_estimated_total_pending_balance'] = balance
  },

  [types.SET_PARTICIPANT_BALANCE](state, balance) {
    state['participant_balance'] = balance
  },

  [types.SET_PENDING_BALANCE](state, balance) {
    state['participant_pending_balance'] = balance
  },

  [types.SET_PARTICIPANT_ADDRESS](state, address) {
    state.user['participant_address'] = address
  },

  [types.SET_PARTICIPANT_DATE_OF_BIRTH](state, dateOfBirth) {
    state.user['date_of_birth'] = dateOfBirth
  },
}

export const getCookie = name =>
  document.cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)')?.pop() || ''

const actions = {
  subscribeUser({ state, commit, getters, dispatch }, userId) {
    if (Object.prototype.hasOwnProperty.call(state.pusherChannels, userId)) {
      // already listening for events on this user
      return false
    }
    const channel = pusher.subscribe(userId)
    channel.bind(pusherEvents.UPDATE, (payload = {}) => {
      commit(types.UPDATE_USER, payload)
      if (payload.error) {
        notifier.error(payload.error)
      }

      if (getters.isHyperwalletCashoutEnabled) {
        if (payload.event_type === 'CASHOUT_REQUEST_FAILED') {
          let errorMessage = `${payload.reason}.`

          if (payload.error_code === 'CASHOUT_COOLING_OFF') {
            if (payload.reason === 'Already requested a cashout today') {
              errorMessage = 'You can only cashout once per calendar day.'
            } else {
              const duration = intervalToDuration({
                start: Date.now(),
                end: Date.parse(payload.next_cashout_time),
              })

              errorMessage = `You can next cashout in ${formatDuration(
                duration
              )}.`
            }
          }

          notifier.error(errorMessage, {
            title: `Cashout request has failed`,
            duration: 0,
          })
          trackAccountEvent(
            'hyperwallet_cash_out_failed',
            errorMessage,
            'notification'
          )
        }

        if (payload.event_type === 'CASHOUT_CREATED_EVENT') {
          dispatch('fetchParticipantBalance')
          const successMessage =
            "We've sent your cashout to PayPal! They will send you an email to confirm once it arrives. This might take up to 24 hrs."

          notifier.success(successMessage, {
            title: 'Cashout successful',
            duration: 0,
          })

          trackAccountEvent(
            'hyperwallet_cash_out_successful',
            successMessage,
            'notification'
          )
        }
      }

      if (payload.active_study_id === null) {
        // if this pusher event has cleared the user's active
        // study, handle clearing it.
        dispatch('participant/submissions/clearActiveStudy', undefined, {
          root: true,
        })
      }
    })

    commit(types.SUBSCRIBE_USER, userId)
  },

  fetchUser: throttle(
    async ({ commit, getters }, userId) => {
      // grab the user id out of state if not passed-in
      userId = userId || getters.userId
      if (!userId) {
        return Promise.resolve()
      }
      const user = await http.get(endpoints.USERS(userId))
      rum.setGlobalContextProperty(
        'service_margin_percentage',
        user?.service_margin_percentage
      )
      await commit(types.SET_USER, user)
      return user
    },
    1000,
    { trailing: false }
  ),

  setUser({ commit }, user) {
    commit(types.SET_USER, user)
  },

  async setupUser({ commit, dispatch, getters, rootState }, sub) {
    if (getters.userId) {
      // this action only needs to be called once
      // directly after first login
      return Promise.resolve()
    }

    const user = await dispatch('fetchUser', sub)
    const { id: userId } = user
    const userType = user.user_type.toLowerCase()

    // add routes
    addUserTypeSpecificRoutes(userType)

    // listen for pusher updates
    dispatch('subscribeUser', userId)

    // check for PA enabled
    commit(
      types.SET_PA_ENABLED,
      document.getElementById('app').classList.contains('pa-enabled')
    )

    // setup integrations
    initialisePendo(user)

    // setup DD RUM
    dispatch('initRum')

    // use the reactive user here as it's reevaluated for each event
    setUserContext(() => getters.user)

    if (getters.isResearcher) {
      pendo.track(RESEARCHER_APP_INITIAL_LOAD_START)
      hubspot.push(['identify', { email: user.email }])
    }
    // Sends default login event to GA https://developers.google.com/analytics/devguides/collection/gtagjs/events
    gtag.event('login')
    gtag.config({ user_id: userId })
    // Maps 'dimension2' to 'user_type'.
    gtag.config({
      custom_map: { dimension2: 'user_type' },
    })
    // Sends an event that passes 'user_type' as a parameter.
    gtag.event('user_type_dimension', { user_type: userType })

    // Purposeful duplication of user type & id into data layer for now
    window.dataLayer.push({ user_id: userId, user_type: userType })

    // Send login event to snowplow
    trackLogin()

    rollbar.configure({
      payload: {
        person: {
          id: userId,
        },
      },
    })

    // set LaunchDarkly user
    const launchDarklySetUserPromise = (async () => {
      await rootState.global.launchDarklyInitPromise
      await setLDUser(user)
    })()

    // store the LaunchDarkly set user promise in state so we can wait on
    // it in a specific place if we know we need the flag to be on
    commit(types.SET_LAUNCHDARKLY_SET_USER_PROMISE, launchDarklySetUserPromise)

    dispatch('messages/firebaseLogin', undefined, { root: true }).catch(_ => {
      // fail firebase login silently when running in the background
    })

    dispatch('postFingerprint')

    return Promise.resolve(user)
  },

  updateUserInStore({ commit }, user) {
    commit(types.UPDATE_USER, user)
  },

  initRum({ state }) {
    const { user } = state
    rum.setUser({
      id: user.id,
      user_type: user.user_type,
      currency_code: user.currency_code,
      country: user.country,
      date_joined: user.date_joined,
      experimental_group: user.experimental_group,
    })
  },

  async postFingerprint() {
    const fingerprint = await getFingerprint()
    await http
      .post(endpoints.FINGERPRINT, fingerprint, {
        useGlobalErrorHandling: false,
      })
      .catch(error => {
        // HI-1244: Ignore errors on this request.
        // We're seeing some weirdness where this endpoint 404s
        // intermittently, most likely caused by expired access
        // tokens, which triggers an unnecessary full page 404.
        // We don't really need to do that so ignoring but
        // sending to rollbar in case useful for further debug.
        rollbar.error(error)
      })
  },

  setPAEnabled({ commit }, enabled) {
    commit(types.SET_PA_ENABLED, enabled)
  },
  registerTrackingSetup(_, user) {
    // Sends default sign_up event to GA https://developers.google.com/analytics/devguides/collection/gtagjs/events
    gtag.event('sign_up')
    // send separate participant and researcher sign up events to GA (non-standard)
    gtag.event(`${user.user_type}_sign_up`, {
      event_category: 'engagement',
    })

    snowplow.setUserId(user.id)
    // try setting the user id before the registration event
    initialisePendo(user)
  },

  async registerResearcher({ dispatch }, postData) {
    postData = updateDataForActiveRoute(postData)

    let user
    if (isNewAuthEnabled()) {
      const { user: newUser, tokens } = await createResearcher({
        payload: postData,
        csrfToken: getCookie('csrftoken'),
      })

      user = newUser

      await handlePostRegistrationLogin(tokens)
    } else {
      const { user: newUser } = await createResearcher({
        payload: postData,
        csrfToken: getCookie('csrftoken'),
      })
      user = newUser
    }

    dispatch('registerTrackingSetup', user)

    // FLAG_SSG_RESEARCHER_REGISTRATION_FLOW
    // not checking flag here because it's more based on what route they came from
    // and if the flag had been turned on while they were in the middle of registering
    // it'd be a bit of a mess
    if (
      router.currentRoute.value.name === 'auth.register.researcher.multi-step'
    ) {
      await handlePostRegistrationRedirect()
    } else {
      await router.push({ name: 'auth.researcher.onboarding' })
    }
  },

  setResearcherOnboarding({ dispatch, commit, getters }, { userId, ...data }) {
    return http
      .patch(endpoints.auth.SET_RESEARCHER_ONBOARDING(userId), { ...data })
      .then(async data => {
        await commit(types.SET_USER, data)
        await commit(types.SET_RESEARCHER_TYPE, data.researcher_type)
        await setLDUser(getters.user)
      })
      .then(handlePostRegistrationRedirect)
      .catch(() => {})
  },

  async verifyEmail({ commit, getters }, token) {
    if (!token) {
      notifier.error(translate('notifications.verification_error'), {
        duration: 10000,
      })
      return Promise.resolve()
    }
    try {
      // send api request
      await http.get(endpoints.auth.VERIFY_EMAIL(token))
      // if successful, refetch user data

      // TODO: we can maybe re-use fetchUser action here but the
      // throttling is causing issues where new data is fetched
      const userId = getters.userId
      const user = await http.get(endpoints.USERS(userId))
      await commit(types.SET_USER, user)
      notifier.success(
        translate(
          getters.isResearcher
            ? getters.isPendingApproval
              ? 'notifications.verification_success'
              : 'notifications.verification_success_approved'
            : 'notifications.verification_success'
        ),
        {
          duration: 10000,
        }
      )
    } catch (error) {
      rollbar.error(error)
      notifier.error(translate('notifications.verification_error'), {
        duration: 10000,
      })
    }
  },

  verifyPhone({ commit }, data) {
    return http.post(endpoints.auth.VERIFY_PHONE, data).then(res => {
      commit(types.UPDATE_USER, {
        phone_number: data.phone_number,
        is_phone_verified: false,
      })
      return res
    })
  },

  async verifyPhoneCode({ dispatch }, { id, verification_code }) {
    await http.put(endpoints.auth.VERIFY_PHONE_CODE(id), { verification_code })
    await dispatch('fetchUser')
    notifier.success(translate('notifications.phone_verified'))
  },

  /**
   * Confirms entered password matches user's password
   * @param {Object} _ Unused parameter
   * @param {Object} enteredPassword { password: String }
   * @returns {Promise} Resolves when confirmation is successful, or rejects with error notification.
   */
  confirmPassword(_, enteredPassword) {
    return http
      .post(endpoints.auth.CONFIRM_PASSWORD, enteredPassword)
      .catch(_error =>
        notifier.error(translate('notifications.confirm_password_error'), {
          duration: 10000,
        })
      )
  },

  /**
   * Links PayPal
   * @param {Object} commit
   * @param {Object} args PayPal args (code, scope, nonce) || {}
   */
  async linkPaypal({ commit, rootState }, args = {}) {
    const user = await http.post(endpoints.auth.LINK_PAYPAL, {
      ...args,
    })

    commit(types.SET_USER, user)
    return user
  },

  updateProfile(
    { commit, getters },
    { show_notifier = true, ...payload } = {}
  ) {
    const userId = getters.currentUser.id
    return http.patch(endpoints.USERS(userId), payload).then(user => {
      commit(types.SET_USER, user)

      if (show_notifier) {
        notifier.success(translate('notifications.profile_updated'))
      }
    })
  },

  updateProfileSilent({ commit, dispatch, getters }, patch) {
    const userId = getters.currentUser.id
    commit(types.UPDATE_USER, patch)
    return http.patch(endpoints.USERS(userId), patch)
  },

  // When changing a user account's email,
  // the email on the account stays the same until they verify the new address
  // hence not committing the update to store
  patchUser({ getters }, patch) {
    const userId = getters.currentUser.id
    return http.patch(endpoints.USERS(userId), patch)
  },

  getBraintreeToken({ commit }) {
    return http.get(endpoints.BRAINTREE_TOKEN).then(data => {
      const token = data.bt_token
      commit(types.SET_BT_TOKEN, token)
      return Promise.resolve(token)
    })
  },

  getBankTransferDetails() {
    return http.get(endpoints.BANK_TRANSFER)
  },

  async getSavedPaymentMethods({ commit, dispatch, getters }) {
    try {
      const { results } = await http.get(
        endpoints.PAYMENT_METHODS(getters.userId)
      )
      const deleteSavedPaymentMethodsWithoutBillingAddress = results =>
        results.filter(result => {
          if (!result.billing_address) {
            dispatch('deleteSavedPaymentMethod', result.token)
            return false
          }
          return result
        })
      const savedPaymentMethods =
        deleteSavedPaymentMethodsWithoutBillingAddress(results)
      commit(types.SET_SAVED_PAYMENT_METHODS, savedPaymentMethods)
      return savedPaymentMethods
    } catch {
      notifier.error(translate('notifications.get_saved_payments_error'), {
        duration: 0,
      })
    }
  },

  deleteSavedPaymentMethod({ getters }, token) {
    return http
      .delete(endpoints.DELETE_PAYMENT_METHOD(getters.userId, token))
      .catch(() => translate('notifications.delete_saved_payment_error'))
  },

  createPaymentMethodNonce({ getters }, token) {
    return http
      .get(endpoints.PAYMENT_METHOD_NONCE(getters.userId, token))
      .then(({ nonce }) => {
        return Promise.resolve(nonce)
      })
  },

  topupAccount(
    { dispatch, commit, state, getters },
    { payload, showSuccessNotifier }
  ) {
    return http
      .post(
        endpoints.TOPUP(state.user.id),
        {
          ...payload,
          manage: true,
        },
        { useGlobalErrorHandling: false }
      )
      .then(data => {
        const {
          top_up_amount: amountPence,
          balance,
          available_balance,
          transaction_id,
        } = data
        const currencyCode = getters.currencyCode
        const currencySymbol = getters.currencySymbol
        const amountFormatted = formatMoney(amountPence)
        const formattedString = `${currencySymbol}${amountFormatted}`
        const amountPounds = amountPence / 100
        commit(types.UPDATE_BALANCE, { balance, available_balance })
        // we actually need to re-fetch some more user data
        // here so the above could potentially be removed. But leaving
        // for now as doesn't really hurt.
        dispatch('fetchUser')

        if (showSuccessNotifier) {
          notifier.success(
            translate('notifications.account_topped_up', {
              top_up_amount: formattedString,
            })
          )
        }
        // Sends default purchase event to GA https://developers.google.com/analytics/devguides/collection/gtagjs/events
        gtag.event('purchase', {
          transaction_id,
          value: amountPounds,
          currency: currencyCode,
        })
        // Tracks Google ad conversion event
        gtag.event('conversion', {
          send_to: 'AW-740080876/dXLzCOfsiKIBEOz58uAC',
          transaction_id,
          value: amountPounds,
          currency: currencyCode,
        })
        return Promise.resolve()
      })
  },

  deleteAccount({ dispatch, state }, data) {
    return http.delete(endpoints.USERS(state.user.id), { data }).then(() => {
      dispatch('oidc/signOutOidc', null, { root: true })
    })
  },

  updatePassword({ getters }, data) {
    return http
      .post(endpoints.auth.UPDATE_PASSWORD(getters.userId), data)
      .then(() => {
        notifier.success(translate('notifications.password_changed'))
      })
  },

  async fetchResearcherReferralCampaign({ commit, getters }) {
    const userId = getters.userId
    const referralCampaign = await http.get(
      endpoints.RESEARCHER_REFERRAL_CAMPAIGN(userId)
    )

    // temporary workaround for the page not breaking when they arent yet eligible

    const tempHardCodedCampaign = {
      credit: {
        GBP: {
          minimum_spend: 10000,
          recipient_credit: 2500,
          referrer_credit: 2500,
        },
        USD: {
          minimum_spend: 12500,
          recipient_credit: 3000,
          referrer_credit: 3000,
        },
      },
    }

    const hasReferralCampaign = Object.keys(referralCampaign).includes('credit')

    commit(
      types.SET_REFERRAL_CAMPAIGN,
      hasReferralCampaign ? referralCampaign : tempHardCodedCampaign
    )

    return hasReferralCampaign ? referralCampaign : tempHardCodedCampaign
  },

  createResearcherReferrals({ getters }, referee_emails) {
    const userId = getters.userId
    return http.post(endpoints.RESEARCHER_REFERRALS(userId), {
      referee_emails,
    })
  },

  async claimResearcherReferral(
    { getters, commit },
    { referralId, workspaceId }
  ) {
    const userId = getters.userId
    await http.post(endpoints.RESEARCHER_REFERRAL_CLAIM(userId, referralId), {
      workspace_id: workspaceId,
    })
    const referrals = await http.get(endpoints.RESEARCHER_REFERRALS(userId))
    await commit(types.SET_RESEARCHER_REFERRALS, referrals)
  },

  fetchInvoices({ commit, getters }) {
    return http.get(endpoints.INVOICES(getters.userId)).then(invoices => {
      commit(types.SET_INVOICES, invoices.results)
    })
  },

  downloadInvoice({ dispatch, getters }, invoiceId) {
    const userId = getters.userId
    dispatch('global/setLoading', true, { root: true })
    return http
      .get(endpoints.DOWNLOAD_INVOICE(userId, invoiceId), {
        responseType: 'blob',
      })
      .then(data => {
        download(data, `prolific-invoice-${invoiceId}.pdf`, 'application/pdf')
        dispatch('global/setLoading', false, { root: true })
      })
      .catch(_error => {
        dispatch('global/setLoading', false, { root: true })
      })
  },

  requestStripeInvoice({ getters }, data) {
    return http.post(endpoints.STRIPE_INVOICES, data).then(() => {
      notifier.success(translate('notifications.email_invoice_successful'))
    })
  },

  resendEmailVerification({ state }) {
    return http.post(endpoints.auth.RESEND_EMAIL_VERIFICATION)
  },

  triggerVerificationDialogAction({ commit }, value) {
    commit(types.TRIGGER_VERIFICATION_DIALOG, value)
  },

  resendCashoutEmailVerification() {
    return http
      .post(endpoints.auth.RESEND_CASHOUT_EMAIL_VERIFICATION)
      .then(() => {
        notifier.success(
          translate('notifications.cashout_email_verification_sent')
        )
      })
  },

  async cashout({ commit, getters }, endpoint = endpoints.auth.CASHOUT) {
    const user = getters.currentUser
    const hasHyperwalletAccount = getters.hasHyperwalletAccount
    const { id } = user
    try {
      await http.post(endpoint(id))
      if (!hasHyperwalletAccount) {
        commit(types.UPDATE_USER, { balance: 0 })
      }
      return await Promise.resolve()
    } catch (error) {
      return await Promise.reject(error)
    }
  },

  async instantCashout({ dispatch }) {
    return await dispatch('cashout', endpoints.auth.INSTANT_CASHOUT)
  },

  fetchContactForm({ commit }, id) {
    return http.get(endpoints.CONTACT_FORM(id))
  },

  submitContactForm({ commit }, data) {
    return http.post(endpoints.CONTACT, data)
  },

  fetchCountries({ state, commit }) {
    if (!isEmpty(state.countries)) {
      return Promise.resolve(state.countries)
    }
    return http.get(endpoints.auth.COUNTRIES).then(countries => {
      commit(types.SET_COUNTRIES, countries)
      return countries
    })
  },

  fetchUSStates({ state, commit }) {
    if (!isEmpty(state.USStates)) {
      return Promise.resolve(state.USStates)
    }
    return http.get(endpoints.auth.US_STATES).then(USStates => {
      commit(types.SET_US_STATES, USStates)
      return USStates
    })
  },

  saveWaitlistData({ commit, getters }, data) {
    const countries = getters.countries

    // If prescreeners contain country of residence or current country of residence, replace the value with the country name
    if (data?.prescreeners?.length > 0) {
      data.prescreeners = data.prescreeners.map(prescreener => {
        if (
          [
            COUNTRY_OF_RESIDENCE_FILTER_ID,
            CURRENT_COUNTRY_OF_RESIDENCE_FILTER_ID,
          ].includes(prescreener.filter_id)
        ) {
          return {
            ...prescreener,
            answer: countries[prescreener.answer],
          }
        }
        return prescreener
      })
    }

    commit(types.SAVE_WAITLIST_DATA, {
      ...data,
      countryOfResidenceCode: data.countryOfResidence,
      countryOfResidence: countries[data.countryOfResidence],
      countryOfBirth: countries[data.countryOfBirth],
    })
  },

  async fetchParticipantBalance({ commit, getters }) {
    const userId = getters.userId
    try {
      const balances = await http.get(
        endpoints.auth.PARTICIPANT_BALANCE(userId, {
          useGlobalErrorHandling: false,
        })
      )

      commit(
        types.SET_PARTICIPANT_ESTIMATED_TOTAL_BALANCE,
        balances?.estimated_total_balance
      )
      commit(
        types.SET_PARTICIPANT_ESTIMATED_TOTAL_PENDING_BALANCE,
        balances?.estimated_total_pending_balance
      )
      commit(types.SET_PARTICIPANT_BALANCE, balances?.balance_by_currency)
      commit(types.SET_PENDING_BALANCE, balances?.pending_balance_by_currency)
      return Promise.resolve()
    } catch (error) {
      return Promise.reject(error)
    }
  },

  async updateParticipantAddress({ commit, dispatch, getters }, addressData) {
    const userId = getters.userId
    try {
      await http.post(
        endpoints.auth.UPDATE_PARTICIPANT_ADDRESS(userId),
        addressData
      )
      commit(types.SET_PARTICIPANT_ADDRESS, addressData)
      await dispatch('fetchUser')
      return Promise.resolve(addressData)
    } catch (error) {
      return Promise.reject(error)
    }
  },

  async updateParticipantDateOfBirth(
    { commit, dispatch, getters },
    dateOfBirth
  ) {
    const userId = getters.userId
    try {
      await http.post(
        endpoints.auth.UPDATE_PARTICIPANT_DATE_OF_BIRTH(userId),
        dateOfBirth
      )
      const { date_of_birth } = dateOfBirth
      commit(types.SET_PARTICIPANT_DATE_OF_BIRTH, date_of_birth)
      await dispatch('fetchUser')
      return Promise.resolve(date_of_birth)
    } catch (error) {
      return Promise.reject(error)
    }
  },

  fetchResearcherReferrals: async (
    { commit, getters },
    { includeClaimed = false } = {}
  ) => {
    try {
      const referrals = await http.get(
        endpoints.RESEARCHER_REFERRALS(getters.userId, includeClaimed)
      )
      await commit(types.SET_RESEARCHER_REFERRALS, referrals)
    } catch (error) {
      rollbar.error('Failed to fetch researcher referrals', error)
    }
  },
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const localFlags = () => {
  const flags = localStorage.flags || ''
  return flags.split(' ')
}

const paymentsGetters = {
  isHyperwalletCashoutEnabled: ({ user }) => {
    const hyperwalletCashoutEnabled = user?.hyperwallet_cashout_enabled
    return (
      !!hyperwalletCashoutEnabled &&
      isAfter(new Date(), parseISO(hyperwalletCashoutEnabled))
    )
  },
  hasNoPayPalLinked: (_state, { isCashoutVerified }) => !isCashoutVerified,
  hasPayPalLinkedWithMissingDetails: (
    _state,
    { isCashoutVerified, hasDateOfBirth, hasAddress }
  ) => isCashoutVerified && (!hasDateOfBirth || !hasAddress),
  // If user has supplied all the required data for HyperWallet, it's likely they have had a HyperWallet Account created
  hasProvidedAllHyperwalletRequiredData: (
    _state,
    { isCashoutVerified, hasDateOfBirth, hasAddress }
  ) => isCashoutVerified && hasDateOfBirth && hasAddress,
  // If user has Hyperwallet cashouts and multi currency enabled and has linked PayPal, they have a Hyperwallet account
  hasHyperwalletAccount: (
    _state,
    { isHyperwalletCashoutEnabled, isMultiCurrencyEnabled, isCashoutVerified }
  ) =>
    isHyperwalletCashoutEnabled && isMultiCurrencyEnabled && isCashoutVerified,
  isCashoutVerified: ({ user }) => user?.is_cashout_email_verified,
  isMultiCurrencyEnabled: ({ user }) => !!user?.multicurrency_enabled,
  isParticipantWithMultipleBalances: (_state, { participantBalance }) =>
    participantBalance && participantBalance.length > 1,
  isThreeDSecureDisabled: ({ user }) => user?.is_3ds_disabled,
  payeeStatus: ({ user }) => user?.payee_status,
  hasTransferMethods: ({ user }) => user?.has_valid_transfer_methods,
  hasHyperwalletProblem: ({ user }) =>
    user?.payee_status !== 'ACTIVE' && !user?.has_valid_transfer_methods,
  hasHyperwalletUnsupportedcountry: (_state, { address }) => {
    // If the country or country code is missing, return false as we
    // can't determine if the country is supported. It's more likely the
    // participant has not provided an address yet.
    if (!address?.country_code || !address?.country) return false

    return !SUPPORTED_HYPERWALLET_COUNTRIES.includes(
      address.country_code.toUpperCase()
    )
  },
  // Returns the reason for the cashout cooloff if the user is in a cooloff period,
  // otherwise returns false
  cashoutCooloffReason: ({ user }) => {
    const {
      hyperwallet_cashout_cooloff_reason,
      hyperwallet_cashout_cooloff_time,
    } = user
    const isAfterCooloffTime = isAfter(
      new Date(),
      parseISO(hyperwallet_cashout_cooloff_time)
    )
    return isAfterCooloffTime ? false : hyperwallet_cashout_cooloff_reason
  },
  hasInvalidAddress: ({ user }) => {
    const address = user?.address
    const hasNoAddress = !address || !address?.line_1

    if (hasNoAddress) return true

    const { line_1, city, postcode, county, country } = address
    const validLine1 = isValidAddressLine(line_1)
    const validCity = isValidCity(city)
    const validCounty = isValidStateProvince(county)
    const validPostcode = isValidPostalCode(country, postcode)
    return !(validLine1 && validCity && validCounty && validPostcode)
  },
  hasInvalidFirstName: ({ user }) => !isValidName(user.first_name),
  hasInvalidLastName: ({ user }) => !isValidName(user.last_name),
  hasInvalidName: (_state, { hasInvalidFirstName, hasInvalidLastName }) =>
    hasInvalidFirstName || hasInvalidLastName,
}

// TODO: improve structure of this (maybe split into sections) and add "is" prefix to relevant getters
const getters = {
  ...paymentsGetters,
  experimentalGroup: ({ user }) =>
    (user &&
      user.experimental_group &&
      Number.parseInt(user.experimental_group)) ||
    0,
  isInExperimentalGroupRange:
    (_state, { experimentalGroup: group }) =>
    (start, end) =>
      group >= start && group <= end,
  isBetaTester: ({ user }) => user && user.beta_tester === true,
  isStaff: ({ user }) => user && user.is_staff === true,
  isAwaitingActivation: ({ user }) => user && user.is_awaiting_activation,
  isCountryValid: ({ user }) => user && user.is_country_valid,
  user: ({ user }) => user,
  currentUser: ({ user }) => user,
  isBanned: ({ user }) => user && user.status === 'BANNED',
  isLoggedIn: ({ user }) => !isEmpty(user),
  isOnHold: ({ user }) => user && user.status === 'ON_HOLD',
  isShadowbanned: ({ user }) => user && user.status === 'SHADOWBANNED',
  isVerified: ({ user }) => user && user.is_verified,
  isEmailVerified: ({ user }) => user && user.is_email_verified,
  isPhoneVerified: ({ user }) => user?.is_phone_verified,
  isPendingApproval: ({ user }) => user && user.is_pending_approval,
  notAcceptedPrivacyPolicy: ({ user }) =>
    !isEmpty(user) && !user.privacy_policy,
  notAcceptedTermsAndConditions: ({ user }) =>
    !isEmpty(user) && !user.terms_and_conditions,
  userId: ({ user }) => user.id,
  fullName: ({ user }) => [user.first_name, user.last_name].join(' '),
  address: ({ user }) => user?.address,
  // line_1 is a required field, so if it's present, the user has an address
  hasAddress: ({ user }) => user?.address?.line_1,
  currencyCode: ({ user }, _getters, _rootState, rootGetters) => {
    /**
     * AC 31/05/22: This getter is used is a lot of places so this is
     * a quick/lazy approach to take the currency code from the active
     * workspace's wallet instead of user if there is one.
     *
     * TODO: After workspaces is rolled out fully we should refactor to
     * remove currency code logic from this file.
     */
    const wallet = rootGetters['researcher/workspaces/wallet']
    return (
      wallet?.currency_code?.toUpperCase() ||
      user?.currency_code?.toUpperCase() ||
      'GBP'
    )
  },
  isGBP: (_state, { currencyCode }) => currencyCode === 'GBP',
  isUSD: (_state, { currencyCode }) => currencyCode === 'USD',
  currencySymbol: (_state, { currencyCode }) =>
    codeToSymbol[currencyCode] || codeToSymbol.GBP,
  isResearcher: ({ user }) =>
    user.user_type && user.user_type.toLowerCase() === 'researcher',
  isParticipant: ({ user }) =>
    user.user_type && user.user_type.toLowerCase() === 'participant',
  unreadMessageCount: ({ user }) => user.unread_message_count,
  activeStudyId: ({ user }) => user.active_study_id,
  referralCampaign: ({ referralCampaign }) => referralCampaign,
  referralHistory: ({ researcherReferrals }) =>
    researcherReferrals?.as_referrer ?? [],
  unclaimedReferrals: (_state, { referralHistory = [] }) => {
    return referralHistory.filter(
      referral => !referral.claimed && referral.expiry_days >= 0
    )
  },
  claimedReferrals: (_state, { referralHistory = [] }) => {
    return referralHistory.filter(referral => referral.claimed)
  },
  potentialEarningsFromReferrals: (
    _state,
    { unclaimedReferrals, currencyCode }
  ) => {
    return sumReferralCredits(unclaimedReferrals, currencyCode)
  },
  paidEarningsFromReferrals: (_state, { claimedReferrals, currencyCode }) => {
    return sumReferralCredits(claimedReferrals, currencyCode)
  },
  referralIncentive: ({ user }, { formatAmountWithCurrencySymbol: format }) => {
    const { referral_incentive: incentive = {} } = user
    return {
      amountGive: format(incentive.recipient_credit, 0),
      amountGet: format(incentive.referrer_credit, 0),
      repSampleCreditsGet: incentive.referrer_rep_sample_credit,
      minTopupRequired: format(incentive.minimum_topup, 0),
    }
  },
  hasResearcherReferrerCredit: ({ researcherReferrals }, { currencyCode }) =>
    researcherReferrals.as_referrer?.some(
      referral =>
        referral.credit[currencyCode].referrer_credit > 0 &&
        referral.claimable &&
        referral.expiry_days >= 0
    ),
  researcherReferrerCoupons: ({ researcherReferrals }, { currencyCode }) =>
    researcherReferrals.as_referrer
      ?.filter(
        referral =>
          referral.credit[currencyCode].referrer_credit > 0 &&
          referral.claimable &&
          referral.expiry_days >= 0
      )
      .map(referral => {
        const { credit, ...rest } = referral
        return {
          ...rest,
          credit: credit[currencyCode],
        }
      }),
  hasResearcherRecipientCredit: ({ researcherReferrals }, { currencyCode }) => {
    if (isEmpty(researcherReferrals.as_recipient)) return false
    const recipientReferrals = researcherReferrals.as_recipient
    if (recipientReferrals.expiry_days < 0) return false
    const credit = recipientReferrals.credit[currencyCode].recipient_credit
    const currentSpend = recipientReferrals.current_spend
    return credit > 0 && currentSpend < credit
  },
  researcherRecipientCredit: ({ researcherReferrals }, { currencyCode }) =>
    researcherReferrals.as_recipient.credit?.[currencyCode],
  researcherRecipientExpiryDate: ({ researcherReferrals }, { currencyCode }) =>
    researcherReferrals.as_recipient?.expiry_date,
  formatAmountWithCurrencySymbol:
    (_state, { currencySymbol }) =>
    (amount, precision) =>
      formatMoneyWithCurrencySymbol(currencySymbol, amount, precision),
  savedPaymentMethods: ({ savedPaymentMethods }) => savedPaymentMethods,
  invoices: ({ invoices }) => invoices,
  billingAddress: ({ user }) => user.billing_address,
  // TODO: Delete the following getter once all participants are on Hyperwallet
  cashoutEnabled: ({ user, participant_balance }) => {
    // This is needed even with the participantBalanceInGBP getter
    const participantBalanceInGBP =
      getParticipantBalanceInGBP(participant_balance) || 0
    if (user.status !== 'OK') return participantBalanceInGBP !== 0

    return (
      user.can_cashout_enabled &&
      user.cashout_email &&
      user.is_cashout_email_verified
    )
  },
  triggerVerificationDialog: ({ triggerVerificationDialog }) =>
    triggerVerificationDialog,
  instantCashoutEnabled: ({ user }, { cashoutEnabled }) =>
    cashoutEnabled && user.can_instant_cashout,
  pendingCashout: ({ user }) => user.pending_cashout || null,
  countries: ({ countries }) => countries,
  isRoW: ({ user }) => !isEmpty(user) && !['GB', 'US'].includes(user.country),
  availableBalance: ({ user }) => user.available_balance,
  participantEstimatedTotalBalance: ({ participant_estimated_total_balance }) =>
    participant_estimated_total_balance || null,
  participantEstimatedTotalPendingBalance: ({
    participant_estimated_total_pending_balance,
  }) => participant_estimated_total_pending_balance || [],
  participantBalance: ({ participant_balance }) => participant_balance || [],
  participantPendingBalance: ({ participant_pending_balance }) =>
    participant_pending_balance || [],
  participantBalanceInGBP: ({ participant_balance }) =>
    getParticipantBalanceInGBP(participant_balance),
  hasParticipantBalance: (_state, { participantBalance }) =>
    participantBalance &&
    participantBalance.some(currency => currency.amount > 0),
  topupsOverReferralThreshold: ({ user }) =>
    user.topups_over_referral_threshold,
  hasSeenVATNumber: ({ user }) => !isEmpty(user) && user.vat_number !== null,
  USStates: ({ USStates }) => USStates,
  needsToConfirmUSState: ({ user }, getters) =>
    getters.isResearcher && user.needs_to_confirm_US_state,
  waitlistData: ({ waitlistData }) => waitlistData,
  email: ({ user }) => user.email,
  emailPreferences: ({ user }) => user.email_preferences,
  phoneNumber: ({ user }) => user?.phone_number,
  devicePreferences: ({ user }, getters) =>
    getters.isParticipant && {
      device_type_preferences: user.device_type_preferences,
      device_requirement_preferences: user.device_requirement_preferences,
    },
  contentTypePreferences: ({ user }, getters) =>
    getters.isParticipant && user.content_type_preferences,
  studyTypePreferences: ({ user }, getters) =>
    getters.isParticipant && user.study_type_preferences,
  otherPreferences: ({ user }, getters) =>
    getters.isParticipant && user.other_preferences,
  dashboardFiltersPreferences: ({ user }, getters) =>
    getters.isParticipant && user?.study_dashboard_preferences?.filters,
  cashoutEmail: ({ user }) => user.cashout_email,
  dateOfBirth: ({ user }) => user.date_of_birth,
  dateOfBirthLastChanged: ({ user }) => user?.date_of_birth_last_updated,
  hasDateOfBirth: (_state, { dateOfBirth }) => !!dateOfBirth,
  country: ({ user }) => user.country,
  isGlobalRepSampleCountry: (_state, { country }) =>
    GLOBAL_REP_SAMPLE_COUNTRIES.includes(country),
  isTwoStepEnabled: ({ isTwoStepEnabled }) => isTwoStepEnabled,
  hasOnboarded: ({ user }) => {
    const email = user?.onboarding?.email?.completed
    const idVerification = user?.onboarding?.id_verification?.completed
    const phoneNumber = user?.onboarding?.phone_number?.completed
    const question = user?.onboarding?.question?.completed
    const onboardingStudy = user?.onboarding?.onboarding_study?.completed
    return email && idVerification && phoneNumber && question && onboardingStudy
  },
  showAlternativeTermsModal: ({ user }) =>
    user?.participant_terms_and_conditions?.alternative?.show_consent,
  dateJoined: ({ user }) => user?.date_joined,
  hasJoinedAlternativeTermsAndConditions: ({ user }) =>
    user?.participant_terms_and_conditions?.alternative?.consented,
}

export { types as authTypes }

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
  modules: {
    mfa,
  },
}
