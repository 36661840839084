export const DATA_COLLECTION_EXTERNAL = 'external'
export const DATA_COLLECTION_SURVEY_BUILDER = 'survey_builder'
export const DATA_COLLECTION_TASKFLOW = 'taskflow'

export const SURVEY_BUILDER_ANSWER_MIN = 2
export const SURVEY_BUILDER_ANSWER_MAX = 10

export const SURVEY_BUILDER_QUESTION_MIN = 1
export const SURVEY_BUILDER_QUESTION_MAX = 5

// NOTE: `SURVEY_BUILDER_APP_KEY` is populated in the `external_app` field of
// studies by the BE when the study was created with a survey.
export const SURVEY_BUILDER_APP_KEY = 'PROLIFIC_SURVEY'
// NOTE: SURVEY_BUILDER_URL_PREFIX is used in the `external_study_url` field of
// studies created with surveys. It will be suffixed with the survey ID and is
// used by the BE to populate the `external_app` and `external_id` fields of the study.
export const SURVEY_BUILDER_URL_PREFIX = 'https://prolific.com/surveys/'
export const SURVEY_BUILDER_UNRESTRICTED_COUNTRIES = [
  'AR',
  'AT',
  'BE',
  'BG',
  'CA',
  'CY',
  'CZ',
  'DE',
  'DK',
  'EE',
  'ES',
  'FI',
  'FR',
  'GB',
  'GR',
  'HR',
  'HU',
  'IE',
  'IL',
  'IS',
  'IT',
  'JP',
  'KR',
  'LI',
  'LT',
  'LU',
  'LV',
  'MT',
  'NL',
  'NO',
  'NZ',
  'PL',
  'PT',
  'RO',
  'SE',
  'SI',
  'SK',
  'US',
]
