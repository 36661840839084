/**
 Example event naming convention + properties:

    Label: Present-tense verb Object
      path
      subdomain
      url
      text

    Register: Click Register
      path: /pricing
      subdomain: app
      url: https://www.prolific.com/pricing
      text: Sign Up

    R-Header: Click Studies
      path: /studies/drafts
      subdomain: app
      url: https://www.prolific.com/pricing
      text: Studies

    // Submit Errors
    Register: Submit Error
      type: 'client' | 'api'
      fields: []
      status: // if api

 */

//-- SHARED ------------------------------------------------------
// Helper functions

// get actions from events
// e.g. 'Register: Click Register' -> 'Click Register'
export const getEventAction = (event) => event.split(': ')[1]

// Page views
// Removing page view tracking in Amp due to event limit/costs
// export const VIEW_PAGE = 'View Page'

// Researcher app boot
export const RESEARCHER_APP_INITIAL_LOAD_START = 'R-App Initial Load: Start'
export const RESEARCHER_APP_INITIAL_LOAD_FINISH = 'R-App Initial Load: Finish'

//-- RESEARCHER ---------------------------------------------------

// Home
export const RESEARCHER_HOME_CLICK_NEW_STUDY = 'R-Home: Click New Study'

// Study Form Audience section

export const RESEARCHER_STUDY_FORM_REQUESTED_SAMPLE_SIZE_FIRST_INPUT = 'R-Study Form Requested Sample Size: First Input'
export const RESEARCHER_STUDY_FORM_REQUESTED_SAMPLE_SIZE_SUBSEQUENT_INPUT = 'R-Study Form Requested Sample Size: Subsequent Input'
export const RESEARCHER_STUDY_FORM_REQUESTED_SAMPLE_SIZE_CLICK_PUBLISH_OR_SAVE = 'R-Study Form Requested Sample Size: Click Publish or Save'

export const RESEARCHER_STUDY_FORM_PRESCREENING_MODAL_CLICK_APPLY = 'R-Study Form Prescreening Modal: Click Apply'
export const RESEARCHER_STUDY_FORM_PRESCREENING_MODAL_CLICK_REMOVE = 'R-Study Form Prescreening Modal: Click Remove'

export const RESEARCHER_STUDY_FORM_QUOTA_BALANCING_CLICK_SEX = 'R-Study Form Quota Balancing: Click Sex'
export const RESEARCHER_STUDY_FORM_QUOTA_BALANCING_CLICK_MANAGE_OPTIONS = 'R-Study Form Quota Balancing: Click Manage Options'
export const RESEARCHER_STUDY_FORM_QUOTA_BALANCING_CHANGE_APPLIED_OPTIONS = 'R-Study Form Quota Balancing: Change Applied Options'
export const RESEARCHER_STUDY_FORM_QUOTA_BALANCING_SHOW_PARTICIPANT_COUNT_OBFUSCATION_ERROR = 'R-Study Form Quota Balancing: Show Participant Count Obfuscation Error'
export const RESEARCHER_STUDY_FORM_QUOTA_BALANCING_SHOW_EXCEEDED_MAXIMUM_PARTICIPANTS_WARNING = 'R-Study Form Quota Balancing: Show Exceeded Maximum Participants Warning'

// Submission review
export const RESEARCHER_SUBMISSIONS_FETCH_START = 'R-Submissions Fetch: Start'
export const RESEARCHER_SUBMISSIONS_FETCH_FINISH = 'R-Submissions Fetch: Finish'
export const RESEARCHER_REJECT_SUBMISSION = 'R-Submissions page: Click Reject'
export const RESEARCHER_APPROVE_SUBMISSION = 'R-Submissions page: Click Approve'
export const RESEARCHER_SUBMIT_FEEDBACK = 'R-Submissions page: Click Submit Feedback'
export const RESEARCHER_INCREASE_STUDY_PLACES = 'R-Studies page: Increase Places'
export const RESEARCHER_COMPLETED_STUDY = 'R-Study: Complete Study'

// Rep studies manual reallocation
export const RESEARCHER_OPEN_SUBMISSIONS_PROGRESS_DIALOG = 'R-Manual Reallocation of Rep Studies: Open submissions in-progress dialog (pre-reallocation)'
export const RESEARCHER_SUBMISSIONS_PROGRESS_ACCORDION_INTERACTION = 'R-Manual Reallocation of Rep Studies: Interact with submissions progress accordions'
export const RESEARCHER_OPEN_REALLOCATE_DIALOG = 'R-Manual Reallocation of Rep Studies: Open reallocate dialog (eligible for reallocation)'
export const RESEARCHER_CLICK_REP_STUDIES_FAQ = 'R-Manual Reallocation of Rep Studies: Click on the rep studies FAQ'
export const RESEARCHER_CLICK_REALLOCATE = 'R-Manual Reallocation of Rep Studies: Click reallocate'
export const RESEARCHER_OPEN_REALLOCATED_DIALOG = 'R-Manual Reallocation of Rep Studies: Open study reallocated dialog (post-reallocation)'
export const RESEARCHER_DOWNLOAD_DEMOGRAPHIC_DATA = 'R-Manual Reallocation of Rep Studies: Click on download demographic data (study completed)'

// Workspaces
export const RESEARCHER_WORKSPACES_INVITE_TEAMMATES_MODAL_CLICK_SEND_EMAIL_INVITE = 'R-Workspaces Invite Teammates Modal: Click Send Email Invite'

//-- PARTICIPANT ---------------------------------------------------
export const PARTICIPANT_TOP_ABOUT_YOU_QUESTIONS_START_ANSWERING_QUESTIONS = 'P-Top About You Questions: Start Answering Questions'
export const PARTICIPANT_TOP_ABOUT_YOU_QUESTIONS_FINISH_ANSWERING_QUESTIONS = 'P-Top About You Questions: Finish Answering Questions'
export const PARTICIPANT_TOP_ABOUT_YOU_QUESTIONS_ANSWER_QUESTION = 'P-Top About You Questions: Answer Question'
export const PARTICIPANT_TOP_ABOUT_YOU_QUESTIONS_SKIP_QUESTION = 'P-Top About You Questions: Skip Question'
export const PARTICIPANT_TOP_ABOUT_YOU_QUESTIONS_DELETE_QUESTION = 'P-Top About You Questions: Delete Question'

// Onboarding
export const PARTICIPANT_ONBOARDING_EXPIRED_TOKEN = 'P-Onboarding: Expired Token'

//-- INTEGRATIONS MARKETPLACE --------------------------------------
export const INTEGRATIONS_MARKETPLACE_SEARCH = 'R-Integrations Marketplace: Search'
export const INTEGRATIONS_MARKETPLACE_SORT = 'R-Integrations Marketplace: Sort'
export const INTEGRATIONS_MARKETPLACE_CATEGORY_FILTER = 'R-Integrations Marketplace: Category Filter'
export const INTEGRATIONS_MARKETPLACE_WEBSITE_LINK_CLICK = 'R-Integrations Marketplace: Website Link Click'
export const INTEGRATIONS_MARKETPLACE_HELP_DOCS_LINK_CLICK = 'R-Integrations Marketplace: Help Docs Click'
export const INTEGRATIONS_MARKETPLACE_CARD_CLICK = 'R-Integrations Marketplace: Card Click'

//-- TOKENS --------------------------------------------------------
export const RESEARCHER_API_TOKENS_CREATE = 'R-API-Tokens: Create'
export const RESEARCHER_API_TOKENS_DELETE = 'R-API-Tokens: Delete'
export const RESEARCHER_API_TOKENS_REVEAL = 'R-API-Tokens: Reveal'
export const RESEARCHER_API_TOKENS_HIDE = 'R-API-Tokens: Hide'
export const RESEARCHER_API_TOKENS_COPY = 'R-API-Tokens: Copy'

//-- SURVEY BUILDER ------------------------------------------------
export const RESEARCHER_SURVEY_BUILDER_DATA_COLLECTION_SURVEY_BUILDER_CLICK = 'R-Survey Builder: Data Collection: Survey Builder Click'
export const RESEARCHER_SURVEY_BUILDER_DATA_COLLECTION_EXTERNAL_CLICK = 'R-Survey Builder: Data Collection: External Click'
export const RESEARCHER_SURVEY_BUILDER_INTERACTION = 'R-Survey Builder: Interaction with the Survey Builder'
export const RESEARCHER_SURVEY_BUILDER_SAVE_DRAFT = 'R-Survey Builder: Save draft'
export const RESEARCHER_SURVEY_BUILDER_PUBLISH = 'R-Survey Builder: Publish'

//-- PARTICIPANT - SORTING & FILTERING -----------------------------
export const PARTICIPANT_STUDIES_CHANGE_SORT = 'P-Studies: Change Sort'
export const PARTICIPANT_ACCOUNT_CHANGE_DEVICE_PREFERENCE = 'P-Account: Change device preference'

//-- AUTOMATION ASSISTANT ------------------------------------------
export const RESEARCHER_AUTOMATION_ASSISTANT_OPEN = 'R-Automation Assistant: Open'
export const RESEARCHER_AUTOMATION_ASSISTANT_CREATE_TOKEN_CLICK = 'R-Automation Assistant: Create token click'
export const RESEARCHER_AUTOMATION_ASSISTANT_DOCS_CLICK = 'R-Automation Assistant: Docs click'
